import { boot } from 'quasar/wrappers';
import { BrowserTracing } from '@sentry/tracing';
import * as Sentry from '@sentry/vue';
export default boot(({ app, router }) => {
  if (process.env.PROD) {
    Sentry.init({
      app,
      dsn: process.env.sentryUrl,
      integrations: [
        new BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(router)
        })
      ],
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0
    });
    Sentry.setTag('version', process.env.APP_VERSION);
  }
});
