import { register } from 'register-service-worker';
import { Notify } from 'quasar';
import { v4 } from 'uuid'
import { ref } from 'vue'

// The ready(), registered(), cached(), updatefound() and updated()
// events passes a ServiceWorkerRegistration instance in their arguments.
// ServiceWorkerRegistration: https://developer.mozilla.org/en-US/docs/Web/API/ServiceWorkerRegistration

const warning = () => {
  Notify.create({
    color: 'negative',
    icon: 'mdi-alert',
    message: 'Failed to update, close other tabs and try again.',
    position: 'top-right',
  });
}

const uniqueID = v4()
let counter = 1
var broadcaster = new BroadcastChannel('Consumer');

broadcaster.onmessage = function(event) {
  if(event.data === 'plus'){
    counter++
  }
  else if (event.data !== uniqueID) {
    broadcaster.postMessage('plus')
  }
}

register(process.env.SERVICE_WORKER_FILE, {
  // The registrationOptions object will be passed as the second argument
  // to ServiceWorkerContainer.register()
  // https://developer.mozilla.org/en-US/docs/Web/API/ServiceWorkerContainer/register#Parameter

  // registrationOptions: { scope: './' },

  ready() {
    if (process.env.DEBUG_LOGS) {
      // eslint-disable-next-line no-console
      console.log('App is being served from cache by a service worker.');
    }
  },

  registered(/* registration */) {
    if (process.env.DEBUG_LOGS) {
      // eslint-disable-next-line no-console
      console.log('Service worker has been registered.');
    }
  },

  cached(/* registration */) {
    if (process.env.DEBUG_LOGS) {
      // eslint-disable-next-line no-console
      console.log('Content has been cached for offline use.');
    }
  },

  updatefound(/* registration */) {
    if (process.env.DEBUG_LOGS) {
      // eslint-disable-next-line no-console
      console.log('New content is downloading.');
    }
  },

  updated(registration) {
    registration.waiting.postMessage({ type: 'SKIP_WAITING' });
    if (process.env.DEBUG_LOGS) {
      // eslint-disable-next-line no-console
      console.log('New content is available; please refresh.');
    }

    notif.value = Notify.create({
      color: 'warning',
      icon: 'mdi-cached',
      message: 'There is an update available. Click to update now.',
      timeout: 0,
      group: false,
      multiLine: true,
      position: 'top-right',
      actions: [
        {
          label: 'Update',
          color: 'white',
          noDismiss: true,
          handler: () => {
            notifAction(registration)
          }
        }
      ]
    });
  },

  offline() {
    if (process.env.DEBUG_LOGS) {
      // eslint-disable-next-line no-console
      console.log(
        'No internet connection found. App is running in offline mode.'
      );
    }
  },

  error(err) {
    if (process.env.DEBUG_LOGS) {
      // eslint-disable-next-line no-console
      console.error('Error during service worker registration:', err);
    }
  }
});

const notif = ref()
const loading = ref(false)

const notifAction = (registration) => {
  // start loading state by recreating the notify
  loading.value = !loading.value 
  notif.value({
    actions: [
      {
        label: 'Update',
        color: 'white',
        loading: loading.value,
        noDismiss: true,
        handler: () => {
          notifAction(registration)
        }
      }
    ]
  })

  // the functionality takes place right after the loading state
  counter = 1
  broadcaster.postMessage(uniqueID)
  setTimeout(() => {
    console.log(counter)
    if(counter > 1){
      // if more that 1 tabs warning and stop loading state by recreating the notify 
      warning()
      loading.value = !loading.value 
      notif.value({
        actions: [
          {
            label: 'Update',
            color: 'white',
            loading: loading.value,
            noDismiss: true,
            handler: () => {
              // start the steps again by running the function
              notifAction(registration)
            }
          }
        ]
      })
    } else {
      navigator.serviceWorker
        .getRegistrations()
        .then(function(registrations) {
          if (registrations.lenght) {
            for (let navigatorRegistration of registrations) {
              navigatorRegistration.unregister()
            }
            registration.unregister().then(function() {
              window.location.reload(true);
            });
          } else {
            registration.unregister().then(function() {
              window.location.reload(true);
            });
          }
        });
    }
  }, 1000);
}
