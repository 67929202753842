import { axios } from '@/boot/axios';
import echo from '@/commons/echo';
import { mergeDeepRight } from 'ramda';

export interface AppStateInterface {
  loginModal: boolean;
  config: Config;
  lastVersion: string;
  lastChangelog: string;
  changelog: unknown;
}

export interface Alert { icon: string, message: string, type: string, order: number}
export interface Config {
  BILLING_FEATURES_SHOW: boolean;
  BILLING_PRICES_SHOW: boolean;
  DISKS_LATENCY_SHOW?: boolean;
  CLOUD_SERVER_AUTO_UNMOUNT_HOURS: number;
  CUSTOMER_LEAD_REGISTRATION_ENABLED: boolean;
  DEFAULT_HELP_PAGE: string;
  MAINTENANCE: boolean;
  MAINTENANCE_QUEUE: boolean;
  USER_REQUIRE_MOBILE: string;
  USER_REQUIRE_TWOFA: string;
  ENV_PREFIX: string;
  DEDICATED_SERVERS_ENABLED: boolean;
  LANGUAGE: string;
  CLOUD_SERVER_AUDIT: string;
  REQUIRES_CAPTCHA: boolean;
  ALERTS: Alert[];
  RESCALE_CPU_PER_SOCKET_SHOW: boolean;
  RESCALE_CPU_PER_SOCKET_EDIT: boolean;
  FEATURE_FLAGS: {
    [key: string]: {
      description: string;
    };
}
  [key: string]: boolean | number | string | object | undefined; 
}

import { defineStore } from 'pinia';
import { AxiosResponse } from 'axios';
import { changelogStore } from './changelog';

export const appStore = defineStore('app', {
  state: () => ({
    loginModal: false,
    config: {} as Config,
    lastVersion: localStorage.getItem('version_hash') || '0.0.0',
    lastChangelog: localStorage.getItem('changelog_hash') || '0.0.0'
  }),
  actions: {
    openLoginModal() {
      this.loginModal = true;
    },
    closeLoginModal() {
      this.loginModal = false;
    },
    setConfig(config: Config) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      this.config = mergeDeepRight(this.config, config);
    },
    setLastVersion(version: string) {
      this.lastVersion = version;
      localStorage.setItem('version_hash', version);
    },
    setLastChangelog(version: string) {
      this.lastChangelog = version;
      localStorage.setItem('changelog_hash', version);
    },
    loadConfig(customer_id?: string) {
      echo.private('config').listen('.CONFIG', (data: { config: Config }) => {
        this.setConfig(data.config);
      });

      if (customer_id) {
        return axios
          .get(`/customer/${customer_id}/config`)
          .then((response: AxiosResponse<Config>) => {
            const conf = this.getConfig;
            for (const [key,  value] of Object.entries(response.data)) {
              conf[key] = value
            }
            this.setConfig(conf);
            return conf;
          });
      }

      return axios.get('/config').then((response: AxiosResponse<Config>) => {
        this.setConfig(response.data);
        return response;
      });
    }
  },
  getters: {
    getLoginModal(state) {
      return state.loginModal;
    },
    getConfig(state) {
      return state.config;
    },
    maintenanceQueue(state) {
      if (state.config) {
        return state.config.MAINTENANCE_QUEUE;
      }
      return false;
    },
    alerts(state) {
      if (state.config) {
        return state.config.ALERTS;
      }
    },
    maintenance(state) {
      if (state.config) {
        return state.config.MAINTENANCE;
      }
      return false;
    },
    isLatestVersion(state) {
      return state.lastVersion === process.env.APP_VERSION;
    },
    isLastChangelog(state) {
      const test = changelogStore();
      //TODO changelog from other store or from back
      return state.lastChangelog === test.getVersions[0].version;
      // return state.lastChangelog == state.lastChangelog;
    }
  }
});
