/**
 * THIS FILE IS GENERATED AUTOMATICALLY.
 * DO NOT EDIT.
 *
 * You are probably looking on adding startup/initialization code.
 * Use "quasar new boot <name>" and add it there.
 * One boot file per concern. Then reference the file(s) in quasar.conf.js > boot:
 * boot: ['file', ...] // do not add ".js" extension to it.
 *
 * Boot files are your "main.js"
 **/

import iconSet from 'quasar/icon-set/svg-mdi-v6'



import {QLayout,QHeader,QDrawer,QFooter,QPageContainer,QPage,QPageSticky,QToolbar,QToolbarTitle,QBtn,QIcon,QList,QItem,QItemSection,QItemLabel,QSeparator,QCard,QCardActions,QCardSection,QSelect,QRadio,QTooltip,QToggle,QMenu,QTabs,QTab,QTabPanels,QTabPanel,QTable,QTh,QTr,QTd,QTree,QInput,QDialog,QBtnToggle,QSlider,QChip,QMarkupTable,QExpansionItem,QFab,QFabAction,QAvatar,QBadge,QInnerLoading,QUploader,QImg,QLinearProgress,QField,QCheckbox,QSkeleton,Ripple,ClosePopup,Dialog,Notify,AppFullscreen,LocalStorage,Dark} from 'quasar'



export default { config: {"notify":{"position":"top-right"}},iconSet,components: {QLayout,QHeader,QDrawer,QFooter,QPageContainer,QPage,QPageSticky,QToolbar,QToolbarTitle,QBtn,QIcon,QList,QItem,QItemSection,QItemLabel,QSeparator,QCard,QCardActions,QCardSection,QSelect,QRadio,QTooltip,QToggle,QMenu,QTabs,QTab,QTabPanels,QTabPanel,QTable,QTh,QTr,QTd,QTree,QInput,QDialog,QBtnToggle,QSlider,QChip,QMarkupTable,QExpansionItem,QFab,QFabAction,QAvatar,QBadge,QInnerLoading,QUploader,QImg,QLinearProgress,QField,QCheckbox,QSkeleton},directives: {Ripple,ClosePopup},plugins: {Dialog,Notify,AppFullscreen,LocalStorage,Dark} }

