import { defineStore } from 'pinia';
/* eslint-disable no-console */
import echo from '@/commons/echo';
import push from 'push.js';
import { Notify } from 'quasar';

export const notificationsStore = defineStore('notifications', {
  state: () => ({
    channel: '',
    notifications: [] as { message?: string; text: string; popup?: string }[]
  }),
  actions: {
    /**
     * @field { String } text
     * @field { Boolean } popup
     * @field { String } color
     * @field { Integer } timeout? [5000]
     * @field { String } pos? [top-right]
     */
    notify(payload: {
      message?: string;
      text: string;
      popup?: string;
      timeout?: number;
      color?: string;
    }) {
      if (payload.message) {
        payload.text = payload.message;
      } else {
        payload.message = payload.text;
      }
      if (payload.popup) {
        if (push.Permission.get() === 'granted') {
          push.create(payload.text).catch(err => {
            console.error(err);
          });
          return this.addNotification(payload);
        }
      }
      Notify.create(payload);
      this.addNotification(payload);
    },
    subscribe(customerId: string) {
      if (!customerId) {
        return;
      }
      const channel = 'notifications-' + customerId;
      this.setSubscribe(channel);
      echo
        .private(channel)
        .listen(
          '.NOTIFICATION',
          (payload: { message: string; text: string; popup: string }) => {
            this.notify(payload);
          }
        );
    },
    unsubscribe() {
      return new Promise<unknown>(() => {
        if (this.channel?.length) {
          this.reset();
          echo.leave(this.channel);
          this.subscribe('');
        }
      });
    },
    setSubscribe(channel: string) {
      this.channel = channel;
    },
    addNotification(payload: {
      message?: string;
      text: string;
      popup?: string;
    }) {
      this.notifications.push(payload);
    },
    reset() {
      this.notifications = [];
    }
  },
  getters: {
    getNotifications(state) {
      return state.notifications;
    }
  }
});
