import {
  DiskStatusEnum,
  CloudServerStatusEnum,
  TasksStatusEnum,
  CloudServerTaskType,
  CloudDiskTaskType,
  IpAddressTaskType,
  NetworkTaskType,
  FirewallTaskType,
  RouterTaskType,
  StorageAccountTaskType,
  ErrorMessages,
  VpnConcentratorTaskType,
  DedicatedServerTaskType,
  DnsTaskType
} from '@/commons/Enums';

export default {
  status: {
    // Task Status
    [TasksStatusEnum.PENDING]: 'Σε αναμονή',
    [TasksStatusEnum.RUNNING]: 'Εκτελείται',
    [TasksStatusEnum.COMPLETE]: 'Ολοκληρώθηκε',
    [TasksStatusEnum.FAILED]: 'Απέτυχε',
    [TasksStatusEnum.CANCELLED]: 'Ακυρώθηκε',
    [TasksStatusEnum.PROCESSING]: 'Eπεξεργασία',
    [TasksStatusEnum.DISABLING]: 'Απενεργοποίηση',
    // Server task Status
    [CloudServerStatusEnum.PENDING]: 'Σε αναμονή',
    [CloudServerStatusEnum.DONE]: 'Ολοκληρώθηκε',
    [CloudServerStatusEnum.FAILED]: 'Απέτυχε',
    [CloudServerStatusEnum.PROCESSED]: 'Η επεξεργασία έχει ολοκληρωθεί',
    [CloudServerStatusEnum.PROVISIONING]: 'Provisioning',
    [CloudServerStatusEnum.PROCESSING]: 'Eπεξεργασία',
    [CloudServerTaskType.CLOUD_SERVER_CLONE]: 'Clone Cloud Server',
    // Disk task Status
    [DiskStatusEnum.PENDING]: 'Σε αναμονή',
    [DiskStatusEnum.DETACHED]: 'Detached',
    [DiskStatusEnum.ATTACHED]: 'Attached',
    [DiskStatusEnum.PROVISIONING]: 'Provisioning',
    [DiskStatusEnum.FAILED]: 'Απέτυχε',
    [DiskStatusEnum.PROCESSING]: 'Eπεξεργασία',
    // Karfwta
    SHOULD_NO_EXECUTE: 'Η ενέργεια ακυρώθηκε',
    DELETED: 'Διαγράφτηκε',
    CREATED: 'Δημιουργήθηκε',
    PROCESSING: 'Eπεξεργασία',
    ENABLING: 'Ενεργοποίηση',
    PREPARING: 'Προετοιμασία',
    CREATING: 'Δημιουργία',
    DELETING: 'Διαγραφή',
    UPDATING: 'Ενημέρωση',
    RESTORING: 'Αποκατάσταση'
  },
  type: {
    // Server Task Types

    [CloudServerTaskType.CLOUD_SERVER_CREATE]: 'Δημιουργία Server',
    [CloudServerTaskType.CLOUD_SERVER_DELETE]: 'Διαγραφή Server',
    [CloudServerTaskType.CLOUD_SERVER_POWER]:
      'Ενεργοποίηση/Απενεργοποίηση Server',
    [CloudServerTaskType.CLOUD_SERVER_REINSTALL]: 'Επανεγκατάσταση Server',
    [CloudServerTaskType.CLOUD_SERVER_UNMOUNT]: 'Unmount Image',
    [CloudServerTaskType.CLOUD_SERVER_MOUNT]: 'Mount Image',
    [CloudServerTaskType.CLOUD_SERVER_RESCALE]: 'Αυξομείωση Resources Server',
    [CloudServerTaskType.CLOUD_SERVER_ENABLE_VNC]:
      'Ενεργοποίηση VNC στον Server',
    [CloudServerTaskType.CLOUD_SERVER_SNAPSHOT_CREATE]: 'Δημιουργία Snapshot',
    [CloudServerTaskType.CLOUD_SERVER_SNAPSHOT_DELETE]: 'Διαγραφή Snapshot',
    [CloudServerTaskType.CLOUD_SERVER_SNAPSHOT_RESTORE]: 'Επαναφορά Snapshot',
    [CloudServerTaskType.CLOUD_SERVER_NETWORK_CARD_CHANGE]:
      'Αλλαγή Κάρτας Δικτύου',
    [CloudServerTaskType.CLOUD_SERVER_NETWORK_CARD_CREATE]:
      'Δημιουργία Κάρτας Δικτύου',
    [CloudServerTaskType.CLOUD_SERVER_NETWORK_CARD_DELETE]:
      'Διαγραφή Κάρτας Δικτύου',
    [CloudServerTaskType.CLOUD_SERVER_NETWORK_CARD_CONNECT]:
      'Σύνδεση Κάρτας Δικτύου',
    [CloudServerTaskType.CLOUD_SERVER_NETWORK_CARD_DISCONNECT]:
      'Αποσύνδεση Κάρτας Δικτύου',
    [CloudServerTaskType.CLOUD_SERVER_BACKUP_DISABLE]:
      'Απενεργοποίηση/Διαγραφή Backups',
    [CloudServerTaskType.CLOUD_SERVER_BACKUP_RESTORE]: 'Επαναφορά Backup',
    [CloudServerTaskType.CLOUD_SERVER_BACKUP_ENABLE]: 'Ενεργοποίηση Backups',
    [CloudServerTaskType.CLOUD_SERVER_BACKUP_RESTORE_DISKS]:
      'Επαναφορά Backup Δίσκων',
    [CloudServerTaskType.CLOUD_SERVER_FIREWALL_RULES_APPLY]:
      'Εφαρμογή Κανόνων Firewall',
    [CloudServerTaskType.CLOUD_SERVER_FIREWALL_RULES_UNDO]:
      'Αναίρεση εφαρμογής κανόνων Firewall',
    [CloudServerTaskType.CLOUD_SERVER_IMPORT]: 'Εισαγωγή Cloud Server',
    [CloudServerTaskType.CLOUD_SERVER_ENABLE_RESCUE_MODE]:
      'Ενεργοποίηση Rescue Mode',
    [CloudServerTaskType.CLOUD_SERVER_DISABLE_RESCUE_MODE]:
      'Απενεργοποίηση Rescue Mode',

    [CloudServerTaskType.CLOUD_SERVER_DISK_MIGRATE]: 'Μεταφορά Cloud Δίσκου',
    [CloudServerTaskType.CLOUD_SERVER_RESOURCE_POOL_MOVE]:
      'Μεταφορά Server σε Resource Pool',
    [CloudServerTaskType.CLOUD_SERVER_CROSS_MIGRATE]:
      'Cloud Server Cross Migrate',
    [CloudServerTaskType.CLOUD_SERVER_ADVANCED_CONFIGURATION]:
      'Σύνθετη διαμόρφωση Cloud Server',

    // Disk Task Types
    [CloudDiskTaskType.CLOUD_SERVER_DISK_CREATE]: 'Δημιουργία Cloud Δίσκου',
    [CloudDiskTaskType.CLOUD_SERVER_DISK_CHANGE_TYPE]: 'Αλλαγή Τύπου Δίσκου',
    [CloudDiskTaskType.CLOUD_SERVER_DISK_DELETE]: 'Διαγραφή Cloud Δίσκου',
    [CloudDiskTaskType.CLOUD_SERVER_DISK_EXTEND]: 'Επέκταση Cloud Δίσκου',
    [CloudDiskTaskType.CLOUD_SERVER_DISK_MOVE]: 'Μεταφορά Cloud Δίσκου',

    // IP Adresses
    [IpAddressTaskType.IP_ADDRESS_ASSIGN]: 'Εκχώρηση IP Διεύθυνσης',
    [IpAddressTaskType.IP_ADDRESS_MOVE]: 'Μεταφορά IP Διεύθυνσης',
    [IpAddressTaskType.IP_ADDRESS_RELEASE]: 'Αποδέσμευση IP Διεύθυνσης',
    [IpAddressTaskType.IP_ADDRESS_SET_REVERSE]: 'Ορισμός PTR record',

    // Network Task Types
    [NetworkTaskType.PRIVATE_NETWORK_DELETE]: 'Διαγραφή Private Network',
    [NetworkTaskType.PRIVATE_NETWORK_CREATE]: 'Δημιουργία Private Network',
    // Routers

    [RouterTaskType.ROUTER_CREATE]: 'Δημιουργία Router',
    [RouterTaskType.ROUTER_DELETE]: 'Διαγραφή Router',
    [RouterTaskType.ROUTER_FIREWALL_APPLY]: 'Εφαρμογή Κανόνων Firewall',
    [RouterTaskType.ROUTER_FIREWALL_UNDO]:
      'Αναίρεση εφαρμογής Κανόνων Firewall',
    [RouterTaskType.ROUTER_IPSEC_APPLY]: 'Εφαρμογή ενεργειών IPSEC',
    [RouterTaskType.ROUTER_IPSEC_UNDO]: 'Αναίρεση εφαρμογής ενεργειών IPSEC',
    [RouterTaskType.ROUTER_LOAD_BALANCER_CREATE]: 'Δημιουργία Load Balancer',
    [RouterTaskType.ROUTER_LOAD_BALANCER_DELETE]: 'Διαγραφή Load Balancer',
    [RouterTaskType.ROUTER_LOAD_BALANCER_EDIT]: 'Επεξεργασία Load Balancer',
    [RouterTaskType.ROUTER_LOAD_BALANCER_EDIT_MEMBERS]:
      'Επεξεργασία Μελών Load Balancer',
    [RouterTaskType.ROUTER_ADD_NETWORK_CARD]: 'Προσθήκη Κάρτας Δικτύου',
    [RouterTaskType.ROUTER_NETWORK_CARD_DELETE]: 'Διαγραφή Κάρτας Δικτύου',
    [RouterTaskType.ROUTER_NETWORK_CARD_UPDATE]: 'Ενημέρωση Κάρτας Δικτύου',
    [RouterTaskType.ROUTER_RESCALE]: 'Αυξομείωση Resources Router',
    [RouterTaskType.ROUTER_STATIC_ROUTE_APPLY]: 'Εφαρμογή Static Route',
    [RouterTaskType.ROUTER_STATIC_ROUTE_UNDO]:
      'Αναίρεση εφαρμογής Static Route',
    [RouterTaskType.ROUTER_ADD_CERTIFICATE]: 'Προσθήκη Certificate',
    [RouterTaskType.ROUTER_DELETE_CERTIFICATE]: 'Διαγραφή Certificate',
    [RouterTaskType.ROUTER_NAT_APPLY]: 'Εφαρμογή Κανόνων NAT',
    [RouterTaskType.ROUTER_NAT_UNDO]: 'Αναίρεση εφαρμογής Κανόνων NAT',
    [RouterTaskType.ROUTER_REVERSE_PATH_UPDATE]: 'Router reverse path update',
    [RouterTaskType.ROUTER_GATEWAY_APPLY]: 'Διαμόρφωση Gateway',

    // Storage Accounts
    [StorageAccountTaskType.FTP_ACCOUNT_CREATE]: 'Δημιουργία Storage Account',
    [StorageAccountTaskType.FTP_ACCOUNT_EDIT_PASSWORD]:
      'Επεξεργασία Κωδικού Storage Account',
    [StorageAccountTaskType.FTP_ACCOUNT_DELETE]: 'Διαγραφή Storage Account',
    [StorageAccountTaskType.FTP_ACCOUNT_DISABLE]:
      'Απενεργοποίηση Storage Account',
    [StorageAccountTaskType.FTP_ACCOUNT_RESIZE]:
      'Αυξομείωση Χωρητικότητας Storage Account',
    // Firewalls
    [FirewallTaskType.UNDO_CLOUDSERVER_FIREWALL_RULES]:
      'Αναίρεση εφαρμογής Κανόνων Firewall',
    [FirewallTaskType.APPLY_CLOUDSERVER_FIREWALL_RULES]:
      'Εφαρμογή Κανόνων Firewall',

    // VpnConcentrators
    [VpnConcentratorTaskType.VPN_CONCENTRATOR_CREATE]:
      'Δημιουργία VPN Concentrator',
    [VpnConcentratorTaskType.VPN_CONCENTRATOR_DELETE]:
      'Διαγραφή VPN Concentrator',
    [VpnConcentratorTaskType.VPN_CONCENTRATOR_POOL_CREATE]:
      'Δημιουργία IP Pool για VPN Concentrator',
    [VpnConcentratorTaskType.VPN_CONCENTRATOR_POOL_EDIT]:
      'Επεξεργασία IP Pool του VPN Concentrator',
    [VpnConcentratorTaskType.VPN_CONCENTRATOR_POOL_DELETE]:
      'Διαγραφή IP Pool του VPN Concentrator',
    [VpnConcentratorTaskType.VPN_CONCENTRATOR_PROFILE_CREATE]:
      'Δημιουργία Προφίλ για VPN Concentrator',
    [VpnConcentratorTaskType.VPN_CONCENTRATOR_PROFILE_DELETE]:
      'Διαγραφή Προφίλ του VPN Concentrator',
    [VpnConcentratorTaskType.VPN_CONCENTRATOR_PROFILE_EDIT]:
      'Επεξεργασία Προφίλ του VPN Concentrator',
    [VpnConcentratorTaskType.VPN_CONCENTRATOR_USER_CREATE]:
      'Δημιουργία Χρήστη VPN Concentrator',
    [VpnConcentratorTaskType.VPN_CONCENTRATOR_USER_DELETE]:
      'Διαγραφή Χρήστη VPN Concentrator',
    [VpnConcentratorTaskType.VPN_CONCENTRATOR_USER_EDIT]:
      'Επεξεργασία Χρήστη VPN Concentrator',
    [VpnConcentratorTaskType.VPN_CONCENTRATOR_NETWORK_CARD_CREATE]:
      'Δημιουργία Κάρτας Δικτύου για VPN Concentrator',
    [VpnConcentratorTaskType.VPN_CONCENTRATOR_NETWORK_CARD_DELETE]:
      'Διαγραφή Κάρτας Δικτύου από VPN Concentrator',
    [VpnConcentratorTaskType.VPN_CONCENTRATOR_NETWORK_CARD_EDIT]:
      'Επεξεργασία Κάρτας Δικτύου του VPN Concentrator',
    [VpnConcentratorTaskType.VPN_CONCENTRATOR_STATIC_ROUTE_CREATE]:
      'Δημιουργία Static Route για VPN Concentrator',
    [VpnConcentratorTaskType.VPN_CONCENTRATOR_STATIC_ROUTE_UPDATE]:
      'Επεξεργασία Static Route του VPN Concentrator',
    [VpnConcentratorTaskType.VPN_CONCENTRATOR_STATIC_ROUTE_DELETE]:
      'Διαγραφή Static Route του VPN Concentrator',
    [VpnConcentratorTaskType.VPN_CONCENTRATOR_REGENERATE_CONFIG]:
      'Αναβάθμιση Config Χρήστη',

    // DNS
    [DnsTaskType.DNS_ZONE_CREATE]: 'Δημιουργία DNS Zone',
    [DnsTaskType.DNS_ZONE_DELETE]: 'Διαγραφή DNS Zone',
    [DnsTaskType.DNS_ZONE_APPLY]: 'Εφαρμογή DNS Zone Records',
    [DnsTaskType.DNS_ZONE_UNDO]: 'Αναίρεση DNS Zone Records',

    CUSTOMER_RESOURCES_SYNC: 'Customer Resources Sync',
    CUSTOMER_ORDERS_SYNC: 'Customer Orders Sync',
    VMWARE_IMPORT_REPORT_GENERATE: 'Vmware Import Report Generate',

    ROUTER_LOAD_BALANCER_REDIRECT_CREATE:
      'Δημιουργία Router Load Balancer Redirect',
    ROUTER_LOAD_BALANCER_REDIRECT_EDIT:
      'Επεξεργασία Router Load Balancer Redirect',

    [DedicatedServerTaskType.DEDICATED_SERVER_POWER]: 'Power Server'

    // Karfwta
  },
  error: {
    [ErrorMessages.NO_LAST_NETWORK_CARD_DELETE]:
      'Ο Server απαιτεί την ύπαρξη τουλάχιστον μίας κάρτας δικτύου',
    [ErrorMessages.PASSWORD_INSECURE]: 'Μη ισχυρός κωδικός πρόσβασης',
    [ErrorMessages.INVALID_NAME]: 'Μη έγκυρο όνομα',
    // generic validation errors

    VALIDATION_REQUIRED: 'Το πεδίο ${field} απαιτείται.',
    VALIDATION_TYPE_STRING:
      'Το πεδίο ${field} θα πρέπει να είναι τύπου string.',
    VALIDATION_TYPE_FILE: 'Το πεδίο ${field} δεν είναι έγκυρο αρχείο.',
    VALIDATION_MAX: 'Το πεδίο ${field} υπερβαίνει την επιτρεπτή τιμή.',
    VALIDATION_TYPE_ARRAY: 'Το πεδίο ${field} θα πρέπει να είναι πίνακας.',
    VALIDATION_TYPE_BOOLEAN:
      'Το πεδίο ${field} θα πρέπει να είναι τύπου boolean.',
    VALIDATION_NOT_EXISTS: 'Δεν υπάρχει το ${field}.',
    VALIDATION_REGEX_MISMATCH: 'Η επικύρωση του ${field} απέτυχε.',
    VALIDATION_CONFIRM_MISMATCH: 'Η επιβεβαίωση του ${field} δεν ταιριάζει.',
    VALIDATION_MIN: 'Το πεδίο ${field} είναι κάτω της επιτρεπτής τιμής.',
    VALIDATION_CONDITIONAL_REQUIRED:
      'Το πεδίο ${field} προς το παρόν απαιτείται.',
    VALIDATION_UNKNOWN_VALUE: 'Το πεδίο ${field} έχει μη αναμενόμενη τιμή.',
    VALIDATION_TYPE_INTEGER: 'Το πεδίο ${field} πρέπει να είναι τύπου Integer.',
    VALIDATION_DIGIT_RANGE:
      'Το πεδίο ${field} είναι εκτός των επιτρεπτών ορίων.',
    VALIDATION_STARTS_WITH: 'Μη έγκυρη μορφή πεδίου ${field}.',
    VALIDATION_PRESENT: 'Πρέπει να υπάρχει το πεδίο ${field}.',
    VALIDATION_TYPE_EMAIL: 'Το πεδίο ${field} πρέπει να είναι διεύθυνση email.',
    VALIDATION_TYPE_DATE:
      'Το πεδίο ${field} πρέπει να είναι έγκυρη ημερομηνία.',
    VALIDATION_DATE_AFTER:
      'Το πεδίο ${field} δεν καλύπτει την ελάχιστη ημερομηνία ορισμού.',
    VALIDATION_TYPE_NUMERIC: 'Το πεδίο ${field} πρέπει να είναι αριθμός.',

    // business logic errors

    UNKNOWN_INSTANCE_TYPE: 'Άγνωστος τύπος instance',
    NOT_CONFIGURED: 'Δεν έχει τροποποιηθεί',
    CANNOT_ALLOCATE_IP: 'Αποτυχία ορισμού IP διεύθυνσης',
    NO_SUBNET_AVAILABLE: 'Αποτυχία εύρεσης διαθέσιμου δικτύου',
    NO_PUBLIC_IP_AVAILABLE: 'Αποτυχία εύρεσης διαθέσιμης IP διεύθυνσης',
    UNKNOWN_SPOOFGUARD_POLICY: 'Απουσία Spoof Policy',
    NETWORKS_NOT_ARRAY: 'Πρόβλημα επικύρωσης ορίων δικτύου',
    PRICING_OVERRIDE_ERROR: 'Σφάλμα επεξεργασίας τιμολόγισης προϊόντος',
    UNKNOWN_PRODUCT_PRICE_TYPE: 'Άγνωστο είδος τιμής προϊόντος',
    RESOURCE_VALUE_ERROR: 'Σφάλμα επεξεργασίας τιμής recources',
    SMS_KEY_NOT_CONFIGURED: 'Μη ορισμένος αποστολέας SMS',
    NO_AVAILABLE_DATASTORE: 'Αδυναμία παραχώρησης χωρητικότητας',
    NO_AVAILABLE_VNC_PORT: 'Αδυναμία επεξεργασίας virtual console',
    FETCH_SPOOFGUARD_POLICIES_ERROR: 'Σφάλμα SpoofPolicy fetch',
    BACKUPS_ALREADY_ENABLED: 'Τα Backups είναι ήδη ενεργοποιημένα.',
    BACKUPS_UNAVAILABLE_ON_LOCATION:
      'Τα Backups δεν είναι προσωρινά διαθέσιμα σε αυτή την τοποθεσία.',
    BACKUPS_ALREADY_DISABLED: 'Τα Backups είναι ήδη απενεργοποιημένα',
    BACKUPS_DISABLED:
      'Τα Backups είναι απενεργοποιημένα για αυτόν τον Cloud Server.',
    WRONG_RESTORE_POINT: 'Άγνωστο backup restore point.',
    BACKUP_SERVER_UNAVAILABLE: 'Μη διαθέσιμη υποδομή Backup.',
    BACKUP_JOB_RUNNING: 'Ένα backup job βρίσκεται σε εξέλιξη.',
    DISK_SIZE_IS_LESS_THAN_THE_MINIMUM:
      'Η χωρητικότητα του δίσκου είναι μικρότερη από τις ελάχιστες απαιτήσεις του λειτουργικού συστήματος.',
    NAME_ALREADY_IN_USE: 'Το όνομα χρησιμοποιείται ήδη.',
    NETWORK_NOT_READY: 'Το private network είναι μη διαθέσιμο αυτή τη στιγμή.',
    INVALID_IP_ADDRESS: 'Μη έγκυρη μορφή διεύθυνσης IP.',
    GATEWAY_DOES_NOT_BELONG_TO_NETWORK:
      'Το gateway θα πρέπει να είναι εντός των ορίων του subnet.',
    NO_PUBLIC_NETWORK: 'Το δίκτυο δεν βρέθηκε.',
    NO_ACCESS_SSH_KEY: 'Το SSH key δεν βρέθηκε.',
    SERVER_EXISTS_IN_LOAD_BALANCER:
      'Ο Cloud Server είναι μέλος σε Load Balancer.',
    SERVER_MUST_POWERED_OFF:
      'Ο Cloud Server πρέπει να είναι απενεργοποιημένος.',
    UNKNOWN_STATE: 'Άγνωστη κατάσταση Cloud Server.',
    CLOUD_SERVER_NOT_POWERED_OFF:
      'Ο Cloud Server πρέπει να είναι απενεργοποιημένος.',
    OPERATING_SYSTEM_MISMATCH:
      'Δεν μπορεί να χρησιμοποιηθεί το συγκεκριμένο λειτουργικό σύστημα.',
    VNC_ALREADY_SET: 'Η virtual console έχει ήδη ρυθμιστεί.',
    NO_DELETE_PRIMARY: 'Δεν μπορεί να διαγραφεί ο πρωτεύων δίσκος.',
    NO_ACCESS_TARGET_CLOUD_SERVER: 'Δεν υπάρχει πρόσβαση στον Cloud Server.',
    DIFFERENT_SERVER_LOCATION:
      'Δεν μπορούν να μεταφερθούν δίσκοι μεταξύ διαφορετικών τοποθεσιών υποδομής.',
    DIFFERENT_OPERATING_SYSTEM:
      'Δεν μπορούν να μεταφερθούν δίσκοι μεταξύ διαφορετικών λειτουργικών συστημάτων.',
    INVALID_LOCATION: 'Μη έγκυρη τοποθεσία',
    UNKNOWN_LOCATION: 'Άγνωστη τοποθεσία',
    NO_DISK_IMAGE_MOUNTED: 'Δεν υπάρχει mounted image',
    SINGLE_PUBLIC_ALLOWED: 'Επιτρέπεται μόνο μία public κάρτα δικτύου.',
    PUBLIC_CARD_NO_PUBLIC_NETWORK:
      'Η public κάρτα δικτύου πρέπει να συνδεθεί σε public δίκτυο.',
    NETWORK_ALREADY_CONNECTED:
      'Ο server είναι ήδη συνδεδεμένος σε αυτό το δίκτυο.',
    NO_LAST_NETWORK_CARD_DELETE:
      'Δεν μπορούν να διαγραφούν όλες οι κάρτες δικτύου του server.',
    NO_PUBLIC_NETWORK_CARD:
      'Θα πρέπει να υπάρχει public κάρτα δικτύου για να αποδοθούν IP διευθύνσεις.',
    IPBLOCK_IN_USE: 'Το block IP διευθύνσεων είναι σε χρήση.',
    ATTACHABLE_BUSY: 'Απασχολημένο',
    ROUTER_UPLINK_IS_PRIVATE_NETWORK:
      'Ο Router θα πρέπει να έχει public uplink δίκτυο για να αποδοθούν IP διευθύνσεις.',
    IP_AVAILABLE: 'Δεν βρέθηκε η προέλευση της IP',
    SAME_SOURCE_DESTINATION_INSTANCE: 'Ίδια προέλευση και προορισμός',
    ROUTER_HAS_ONLY_ONE_IP_ADDRESS:
      'Δεν μπορούν να αφαιρεθούν όλες οι IP διευθύνσεις από το Router.',
    IP_SEC_USES_THIS_IP_ADDRESS:
      'Αυτή η IP διεύθυνση χρησιμοποιείται από ένα IPSec site.',
    LOAD_BALANCER_USES_THIS_IP_ADDRESS:
      'Αυτή η IP διεύθυνση χρησιμοποιείται από έναν Load Balancer.',
    NETWORK_IN_USE: 'Το δίκτυο είναι σε χρήση.',
    RESTRICTED_ACTION:
      'Δεν μπορεί να διαγραφεί ένα semi-managed private δίκτυο.',
    INVALID_IP_ADDRESS_IN_PRIVATE_NETWORK:
      'Η IP διεύθυνση δεν ανήκει στο δίκτυο.',
    NETWORK_OVERLAP: 'Επικάληψη δικτύων',
    GATEWAY_CANNOT_MATCH_WITH_CIDR:
      'Η IP διεύθυνση του gateway δεν μπορεί να είναι η ίδια με αυτή του Router.',
    ADD_CERTIFICATE_ERROR: 'Άγνωστο σφάλμα κατά την προσθήκη του certificate.',
    DELETE_CERTIFICATE_ERROR:
      'Αγνωστο σφάλμα κατά τη διαγραφή του certificate.',
    CANNOT_HAVE_MORE_NETWORK_CARDS:
      'Έχετε φτάσει το όριο πλήθους των καρτών δικτύου για αυτό το Router.',
    CANNOT_CONNECT_NETWORK_CARD_TO_SAME_NETWORK:
      'Ήδη συνδεδεμένο σε αυτό το δίκτυο.',
    CANNOT_CONFIGURE_SAME_SUBNET: 'Το subnet έχει ήδη ρυθμιστεί.',
    OVERLAPPING_NETWORK_ADDRESS_1: 'Επικάληψη ελέγχου1',
    OVERLAPPING_NETWORK_ADDRESS_2: 'Επικάληψη ελέγχου2',
    SECONDARY_IP_DOES_NOT_BELONG_TO_NETWORK:
      'Η δευτερεύουσα IP διεύθυνση θα πρέπει να ανήκει στο subnet.',
    NETWORK_CARD_TYPE_MISSMATCH_WITH_NETWORK_TYPE:
      'Ο τύπος της κάρτας δικτύου θα πρέπει να είναι ίδιος με αυτόν του δικτύου.',
    IP_SEC_USES_UPLINK_IP_ADDRESS:
      'Αυτή η uplink διεύθυνση IP χρησιμοποιείται από ένα IPSec site.',
    LOAD_BALANCER_USES_UPLINK_IP_ADDRESS:
      'Αυτή η uplink διεύθυνση IP χρησιμοποιείται από έναν Load Balancer.',
    CANNOT_EDIT_PUBLIC_NETWORK_WITH_ANOTHER_ONE:
      'Δεν μπορεί να αλλάξει το uplink δίκτυο.',
    SECONDARY_IPS_NOT_ALLOWED:
      'Δεν είναι δυνατή η χειροκίνητη προσθήκη δευτερεύουσας IP.',
    NETWORK_TYPE_MISSMATCH: 'Πρόβλημα με τον τύπο δικτύου.',
    NETWORK_ADDRESS_CONFLICT: 'IP Address conflict.',
    OVERLAPPING_NETWORK_ADDRESS: 'Overlapping διευθύνσεις δικτύου',
    NETWORK_CARD_IN_USE_FOR_LOAD_BALANCER:
      'Αυτή η κάρτα δικτύου χρησιμοποιείται από έναν Load Balancer.',
    NETWORK_CARD_IN_USE_FOR_IP_SEC:
      'Αυτή η κάρτα δικτύου χρησιμοποιείται από ένα IPSec site.',
    NETWORK_CARD_IN_USE_FOR_STATIC_ROUTING:
      'Αυτή η κάρτα δικτύου χρησιμοποιείται από ένα static route.',
    UPLINK_NETWORK_CARD_MUST_BE_PUBLIC:
      'Η uplink κάρτα δικτύου θα πρέπει να είναι public για να ενεργοποιηθεί αυτή η λειτουργία.',
    REMOTE_ENDPOINT_CANNOT_MATCH_LOCAL_ENDPOINT:
      'Το απομακρυσμένο σημείο δεν μπορεί να είναι το ίδιο με το τοπικό.',
    REMOTE_ENDPOINT_ALREADY_EXISTS: 'Το απομακρυσμένο σημείο υπάρχει ήδη.',
    REMOTE_ENDPOINT_IS_USED_IN_ROUTER:
      'Το απομακρυσμένο σημείο χρησιμοποιείται στον Router.',
    NOT_VALID_CIDR: 'Μη έγκυρο CIDR.',
    INVALID_LOCAL_SUBNET: 'Μη έγκυρο τοπικό subnet.',
    INVALID_PUBLIC_SUBNET: 'Μη έγκυρο δημόσιο subnet',
    LOCAL_SUBNET_MUST_BE_SUBSET_OF_PRIVATE_NETWORK_OR_STATIC_ROUTE:
      'Το τοπικό subnet θα πρέπει να είναι εντός του δικτύου ή εντός static route.',
    INVALID_REMOTE_SUBNET: 'Μη έγκυρο απομακρυσμένο subnet.',
    REMOTE_SUBNET_ALREADY_EXISTS: 'Το απομακρυσμένο subnet υπάρχει ήδη.',
    REMOTE_SUBNET_CANNOT_OVERLAP_PRIVATE_NETWORKS:
      'Το απομακρυσμένο subnet δεν θα πρέπει να κάνει overlap με τα private networks.',
    LOADBALANCER_INVALID_IP_ADDRESS:
      'Επιλέχτηκαν μη έγκυρες IP διευθύνσεις για χρήση από τον Load Balancer.',
    LOADBALANCER_DUPLICATE_PORTS_ERROR: 'Επανάληψη χρήσης ports.',
    INVALID_IP_ADDRESS_FOR_SERVICE: 'Μη έγκυρη IP διεύθυνση για το service.',
    UNABLE_TO_FETCH_DATA: 'Άγνωστο σφάλμα κατά την εκτέλεση.',
    SOURCE_IP_MUST_BE_SPECIFIED: 'Απαιτείται source IP διεύθυνση.',
    DESTINATION_IP_MUST_BE_SPECIFIED: 'Απαιτείται destination IP διεύθυνση.',
    TRANSLATED_IP_MUST_BE_SPECIFIED: 'Απαιτείται translated IP διεύθυνση.',
    INVALID_SOURCE_IP_ADDRESS_FORMAT: 'Μη έγκυρη μορφή source IP διεύθυνσης.',
    INVALID_DESTINATION_IP_ADDRESS_FORMAT:
      'Μη έγκυρη μορφή destination IP διεύθυνσης.',
    INVALID_PREFIX: 'Μη έγκυρο prefix',
    SOURCE_IP_MUST_BE_EQUAL_OR_SUBSET_OF_PRIVATE_NETWORK_OR_STATIC_ROUTE:
      'Η source IP διεύθυνση θα πρέπει να είναι μέρος του subnet του private network ή του static route.',
    SOURCE_IP_STATIC_ROUTE_NO_NEXT_HOP_IN_APPLIED_ON:
      'Το static route θα πρέπει να έχει next hop στο δίκτυο προορισμού.',
    SOURCE_IP_MUST_BE_EQUAL_OR_SUBSET_OF_PRIVATE_NETWORK:
      'Η source IP διεύθυνση θα πρέπει να είναι ίδια ή να ανήκει στο subnet του private network ή του static route.',
    SOURCE_ADDRESS_AND_DESTINATION_ADDRESS_CANNOT_BELONG_TO_THE_SAME_NETWORK:
      'Η source και η destination IP διευθύνσεις δεν μπορούν να ανήκουν στο ίδιο δίκτυο.',
    DESTINATION_IP_NO_STATIC_NEXT_HOP_IN_SOURCE_NETWORK:
      'Η destination διεύθυνση IP δεν θα πρέπει να έχει static route με next hop στο source δίκτυο.',
    INVALID_TRANSLATED_IP: 'Μη έγκυρη μορφή translated IP διεύθυνσης.',
    TRANSLATED_IP_MUST_BE_EQUAL_OR_SUBSET_OF_PRIVATE_NETWORK_OR_STATIC_ROUTE:
      'Η translated IP διεύθυνση θα πρέπει να είναι ίδια ή μέρος του subnet του private network ή του static route.',
    TRANSLATED_IP_STATIC_NEXT_HOP_IS_USED_IN_APPLIED_NETWORK:
      'Το next hop των static routes από τις translated IP διευθύνσεις υπάρχει στο δίκτυο προορισμού.',
    TRANSLATED_IP_MUST_BE_EQUAL_OR_SUBSET_OF_PRIVATE_NETWORK:
      'Η translated IP διεύθυνση θα πρέπει να είναι ίδια ή εντός του subnet του private network.',
    NEXT_HOP_IS_NOT_USED_IN_APPLIED_NETWORK:
      'Το next hop του static route θα πρέπει να υπάρχει στο δίκτυο προορισμού.',
    INVALID_DESTINATION_IP: 'Μη έγκυρη destination IP.',
    UNKNOWN_NAT_TYPE: 'Άγνωστος τύπος NAT.',
    INVALID_SOURCE_PORT_FORMAT: 'Μη έγκυρη μορφή source port.',
    INVALID_SOURCE_PORT_VALUES: 'Μη έγκυρες τιμές source port.',
    INVALID_SOURCE_PORT_VALUE: 'Μη έγκυρη τιμή source port.',
    INVALID_DESTINATION_PORT_FORMAT: 'Μη έγκυρη μορφή destination port.',
    INVALID_DESTINATION_PORT_VALUES: 'Μη έγκυρες τιμές destination port.',
    INVALID_DESTINATION_PORT_VALUE: 'Μη έγκυρη τιμή destination port.',
    INVALID_TRANSLATED_PORT_FORMAT: 'Μη έγκυρη μορφή translated port.',
    INVALID_TRANSLATED_PORT_VALUES: 'Μη έγκυρες τιμές translated port.',
    INVALID_TRANSLATED_PORT_VALUE: 'Μη έγκυρη τιμή translated port.',
    INVALID_NETWORK_ADDRESS: 'Μη έγκυρη διεύθυνση δικτύου.',
    NETWORK_ADDRESS_MATCHES_UPLINK_INTERFACE:
      'Η διεύθυνση δικτύου είναι ίδια με τη διεύθυνση του uplink.',
    INVALID_NEXT_HOP_ADDRESS: 'Μη έγκυρη διεύθυνση next hop.',
    NETWORK_ADDRESS_CONFLICT_WITH_NETWORK_CARD:
      'Υπάρχει conflict μεταξύ της διεύθυνσης δικτύου και άλλου δικτύου.',
    NETWORK_ADDRESS_CANNOT_BE_SUBNET_OF_NETWORK_CARD:
      'Η διαύθυνση δικτύου δεν μπορεί να είναι εντός του subnet διαφορετικού δικτύου.',
    STATIC_ROUTE_IS_IN_USE_FOR_AN_IP_SEC_SITE:
      'Το static route χρησιμοποιείται από ένα IPSec site.',
    STORAGE_SERVER_INACTIVE: 'Μη ενεργός Storage Server',
    NO_DIFFERENCE_IN_ACCOUNT_SIZE:
      'Η χωρητικότητα του Storage Account δεν έχει αλλάξει.',
    CANNOT_RESIZE_ACCOUNT_DUE_TO_ITS_CURRENT_USAGE:
      'Δεν μπορεί να γίνει μείωση πέραν της υπάρχουσας χωρητικότητας.',
    INVALID_FOLDER_FORMAT: 'Μη έγκυρη μορφή φακέλου.',
    FILE_NO_EXISTS: 'Το αρχείο δεν υπάρχει.',
    FOLDER_ALREADY_EXISTS: 'Ο φάκελος υπάρχει ήδη.',
    FOLDER_NO_EXISTS: 'Ο φάκελος δεν υπάρχει.',
    NOT_READY: 'Δεν είναι έτοιμο προς χρήση.',
    FORBIDDEN_IP_ADDRESS: 'Είστε συνδεδεμένοι από ένα restricted δίκτυο.',
    TWOFA_ALREADY_ENABLED: 'Το Two-Factor authentication είναι ήδη ενεργό.',
    TWOFA_NOT_ENABLED: 'Το Two-Factor authentication δεν είναι ενεργό.',
    TWOFA_INVALID: 'Μη έγκυρος κωδικός Two-Factor authentifation.',
    TWOFA_NOT_PENDING:
      'Δεν εκκρεμεί κάποια επιβεβαίωση για το Two-Factor authentication.',
    PHONE_ALREADY_VERIFIED: 'Το κινητό τηλέφωνο είναι ήδη επιβεβαιωμένο.',
    SMS_SEND_ERROR: 'Σφάλμα αποστολής κωδικού επιβεβαίωσης.',
    NO_SAME_PASSWORD: 'Ο κωδικός πρόσβασης χρησιμοποιείται ήδη.',
    WRONG_OLD_PASSWORD: 'Σφάλμα επιβεβαίωσης παλαιού κωδικού πρόσβασης.',
    INVALID_NETWORK: 'Μη έγκυρη μορφή δικτύου.',
    INVALID_NETWORK_PREFIX: 'Μη έγκυρο prefix δικτύου.',
    EMAIL_ALREADY_VERIFIED: 'Η διεύθυνση email έχει ήδη επιβεβαιωθεί.',
    INVALID_SMS_TOKEN: 'Μη έγκυρο Mobile Verification Token.',
    INVALID_SMS_TOKEN_EXPIRED:
      'Το Mobile Verification Token έχει λήξει, παρακαλούμε δημιουργήστε νέο.',
    INVALID_EMAIL_TOKEN: 'Μη έγκυρο Email Verification Token.',
    INVALID_EMAIL_TOKEN_EXPIRED:
      'Το Email Verification Token έχει λήξει, παρακαλούμε δημιουργήστε νέο.',
    INVALID_FORMAT: 'Μη έγκυρη μορφή δεδομένων.',
    LEAD_REGISTRATION_NOT_ENABLED:
      'Οι εγγραφές είναι μη διαθέσιμες προς το παρόν.',
    PRODUCT_PRICING_EXISTS: 'Η τιμολόγιση του προϊόντος υπάρχει ήδη.',
    BUSY: 'Μη δυνατή η εκτέλεση όσο εκτελείται άλλη διεργασία.',
    BACKUP_RUNNING: 'Μη δυνατή η εκτέλεση όσο πραγματοποιείται backup.',
    SERVER_READONLY: 'Μη δυνατή η εκτέλεση σε read-only Cloud Server.',
    SERVER_ACTIVE_SNAPSHOT:
      'Μη δυνατή η εκτέλεση όσο υπάρχει ενεργό snapshot στον cloud server.',
    ANOTHER_DISK_IMAGE_IS_ALREADY_MOUNTED:
      'Δεν μπορεί να ενεργοποιηθεί το rescue mode όσο υπάρχει mounted disk image.',
    CLOUD_SERVER_IS_NOT_IN_RESCUE_MODE: 'Ο server δεν είναι σε rescue mode.',
    POOL_NAME_ALREADY_EXISTS: 'Το όνομα του IP Pool υπάρχει ήδη.',
    CANNOT_EDIT_IP_ADDRESSES:
      'Δεν μπορεί να γίνει επεξεργασία της IP διεύθυνσης.',

    SERVER_IS_IN_RESCUE_MODE: 'Ο Cloud Server είναι σε Rescue Mode',
    SERVER_IS_PROTECTED: 'O Cloud Server είναι Protected',
    ROUTER_IS_PROTECTED: 'Ο Router είναι Protected',
    CANNOT_DELETE_ADMIN_FIREWALL_RULE:
      'Δεν μπορεί να γίνει η διαγραφή του Admin Firewall Rule',
    VPN_NOT_DEPLOYED: 'Το VPN δεν είναι Deployed',
    INVALID_IP_ADDRESS_RANGE: 'Μη έγκυρο IP Address Range',
    LOCAL_ADDRESS_MUST_BE_THE_FIRST_IP_OF_THE_POOL:
      'Η τοπική διεύθυνση πρέπει να είναι η πρώτη IP του Pool',
    INVALID_DNS_IP_ADDRESS: 'Μη έγκυρη DNS IP διεύθυνση',
    INVALID_ROUTE_IP_ADDRESS: 'Μη έγκυρη Route IP διεύθυνση',
    INVALID_ROUTE_NETMASK: 'Μη έγκυρη Route Netmask',
    DISK_IS_ALREADY_STORED_TO_THIS_DATASTORE:
      'Ο Δίσκος είναι ήδη αποθηκευμένος σε αυτό το Datastore',
    NO_AVAILABLE_FREE_SPACE_TO_THE_SELECTED_DATASTORE:
      'Δεν υπάρχει διαθέσιμος στο επιλεγμένο Datastore',
    CLOUD_SERVER_ALREADY_EXISTS_TO_THE_RESOURCE_POOL:
      'Ο Cloud Server υπάρχει ήδη στο Resource Pool',
    EXCEEDED_RESOURCE_POOL_MAX_VM_LIMIT:
      'To μέγιστο όριο resource pool VM υπερέβη',
    CLOUD_SERVER_IS_NOT_READY: 'Ο Cloud Server δεν είναι έτοιμος',
    INVALID_EXPIRATION_DATE: 'Μη έγκυρη ημερομηνία λήξης',
    CANNOT_EDIT_THIS_IP_ADDRESS:
      'Δεν μπορεί να γίνει η επεξεργασία αυτής της IP διεύθυνσης',
    CANNOT_CHANGE_IP_ADDRESS_RESERVATION: 'Δεν μπορεί να αλλάξει ',
    CANNOT_EDIT_PUBLIC_NETWORK:
      'Δεν μπορεί να γίνει η επεξεργασία του Public Network',
    NETWORK_IS_NOT_READY: 'Το Network δεν είναι έτοιμο',

    TOKEN_EXPIRED: 'Το Token έληξε',
    BILLING_UNAVAILABLE: 'Το Billing είναι μη διαθέσιμο',
    FIRMWARE_NOT_FOUND: 'Το Firmware δεν βρέθηκε',
    FAILED_TO_SHUTDOWN: 'Αποτυχία τερματισμού',
    NO_AVAILABLE_IP_FOUND: 'Δεν βρέθηκε διαθέσιμη IP',
    FAILED_TO_APPROVE_IP: 'Αποτυχία έγκρισης IP',
    FAILED_TO_PUBLISH_IP: 'Αποτυχία δημοσίευης IP',
    UNKNOWN_PYLON_CUSTOMER: 'Άγνωστος Pylon Πελάτης',
    ORDER_ALREADY_EXISTS: 'Η παραγγείλια υπάρχει ήδη',
    CANNOT_UPDATE_UNKNOWN_ORDER:
      'Δεν μπορεί να γίνει ανανέωση άγνωστης παραγγελίας',
    INSTANCE_STATUS_IS_DISABLED:
      'Η κατάσταση του Instance είναι απενεργοποιημένο',
    THIS_OPERATION_IS_UNAVAILABLE: 'Αυτή η λειτουργία είναι μη διαθέσιμη',
    INFRASTRUCTURE_IS_UNAVAILABLE: 'Η υποδομή είναι μη διαθέσιμο',
    ERROR_WITH_SCOPES: 'Σφάλμα με τα Scopes',
    DATA_NOT_FETCHED: 'Δεν λήφθηκαν δεδομένα',
    CORES_MUST_BE_MULTIPLE_OF_CORES_PER_SOCKET:
      'Οι πυρήνες πρέπει να είναι πολλαπλάσιοι για κάθε socket',
    NO_OTHER_PUBLIC_NETWORKS: 'Δεν υπάρχουν άλλα δίκτυα',
    CANNOT_CONNECT_UNASSIGNED_CARDS: 'Αδύνατη η Σύνδεση Κάρτας Χωρίς Δίκτυο',
    CANNOT_DISCONNECT_UNASSIGNED_CARDS:
      'Αδύνατη η Αποσύνδεση Κάρτας Χωρίς Δίκτυο',
    IP_ADDRESS_DOES_NOT_BELONG_TO_THIS_NETWORK:
      'Η IP διεύθυνση δεν υπάρχει στο δίκτυο',
    FILE_NOT_FOUND: 'Το αρχείο δεν βρέθηκε',
    INSTANCE_MUST_HAVE_AT_LEAST_ONE_PUBLIC_NETWORK_CARD:
      'Το instance πρέπει να έχει τουλάχιστον μια δημόσια κάρτα δικτύου',
    VPN_CONCENTRATOR_CANNOT_RELEASE_PUBLIC_IP_ADDRESS:
      'Το VPN Concentrator δεν μπορεί να αποδεσμεύσει την δημόσια IP διεύθυνση',
    PRIVATE_IP_ADDRESS_CANNOT_BELONG_TO_PUBLIC_NETWORK:
      'Η ιδιωτική IP διεύθυνση δεν μπορεί να ανήκει σε δημόσιο δίκτυο',
    OVERLAPPING_NETWORK_ADDRESS_WITH_IP_SEC:
      'Overlapping Network Address with IP Sec',
    SECONDARY_CANNOT_MATCH_WITH_PRIMARY_IP:
      'Η δευτερεύων IP δεν μπορεί να είναι ίδια με την κύρια IP',
    NETWORK_CARDS_DO_NOT_BELONG_TO_THIS_ROUTER:
      'Οι κάρτες δικτύου δεν ανήκει σε αυτό το Router',
    STATIC_ROUTE_USES_TUNNEL_IP_ADDRESS:
      'Το static route χρησιμοποιεί tunnel IP Address',
    GLOBAL_PSK_MUST_BE_SET: 'Καθολικό Pre Shared Key πρέπει να οριστεί',
    TUNNEL_CIDR_IS_ALREADY_USED_IN_ROUTER:
      'Το tunnel CIDR χρησιμοποιείται ήδη στον Router',
    TUNNEL_CIDR_IS_ALREADY_USED_IN_ROUTER_INTERNAL_CARD:
      'Το tunnel CIDR χρησιμοποιείται ήδη στην internal κάρτα',
    CANNOT_EDIT_SESSION_TYPE: 'Δεν μπορεί να επεξεργαστεί τον τύπο του session',
    STATIC_ROUTE_USES_TUNNEL_CIDR: 'Το στατικό route χρησιμοποιεί tunnel CIDR',
    IP_SEC_SITE_USES_GLOBAL_PSK:
      'Το IP Sec χρησιμοποιεί καθολικό Pre Shared Key',
    INVALID_TUNNEL_ADDRESS: 'Μη έγκυρη διεύθυνση tunnel',
    INVALID_TUNNEL_PREFIX: 'Μη έγκυρο prefix',
    SERVICE_SOURCE_PORT_ALREADY_EXISTS: 'Το Service Source Port υπάρχει ήδη',
    INVALID_PERSISTENCE_MODE_FOR_TCP: 'Μη έγκυρο persistence mode για το TCP',
    NONE_SAME_PRIVATE_NETWORK: 'Όχι ίδιο ιδίο ιδιωτικό δίκτυο',
    CANNOT_ADD_SAME_CLOUD_SERVER:
      'Δεν μπορεί να γίνει προσθήκη ίδιου Cloud Server',
    UNABLE_TO_FIND_POOL: 'Δεν μπορεί να βρει το Pool',
    NETWORK_ADDRESS_CANNOT_BE_SUBNET_OF_IP_SEC_PEER_SUBNET:
      'Η διεύθυνση δικτύου δεν μπορεί να είναι υποδίκτυο ενός IP Sec peer Subnet',
    IP_SEC_PEER_SUBNET_CANNOT_BE_SUBNET_OF_NETWORK_ADDRESS:
      'IP Sec peer Subnet δεν μπορεί να είναι υποδίκτυο μιας διεύθυνσης δικτύου',
    INVALID_NEXT_HOP: 'Μη έγκυρο next hop',
    OVERLAP_NEXT_HOP_ADDRESS_WITH_TUNNEL_CIDR:
      'Overlap Next Hop Address with Tunnel CIDR',
    CANNOT_ACCESS_NETWORK: 'Δεν υπάρχει πρόσβαση στο δίκτυο',
    CANNOT_ADD_ANOTHER_NETWORK_CARD:
      'Δεν μπορεί να γίνει προσθήκη άλλης κάρτας δικτύου',
    INVALID_NETWORK_TYPE: 'Μη έγκυρος τύπος δικτύου',
    NETWORK_ALREADY_IN_USE: 'Δίκτυο ήδη σε χρήση',
    INVALID_CIDR: 'Μη έγκυρο CIDR',
    INVALID_NETWORK_CARD: 'Μη έγκυρη κάρτα δικτύου',
    INVALID_VALUE: 'Μη έγκυρη τιμή',
    INVALID_CIDR_FOR_ROUTES: 'Μη έγκυρο CIDR για Routes',
    INVALID_ROUTE: 'Μη έγκυρο Route',
    CANNOT_DELETE_PUBLIC_CARD:
      'Δεν μπορεί να γίνει διαγραφή της δημόσιας κάρτας',
    CANNOT_DELETE_LAST_PRIVATE_CARD:
      'Δεν μπορεί να γίνει διαγραφή της τελευταίας ιδιωτικής κάρτας',
    CARD_IS_USED_AS_USER_ROUTE: 'Η κάρτα χρησιμοποιείται ως user route',
    INVALID_START_IP_ADDRESS: 'Μη έγκυρη start IP address',
    POOL_RANGE_OVERPLAPS_EXISTING_POOLS: 'Pool range overlaps existing pools',
    IP_ADDRESS_CONFLICTS_WITH_THE_NETWORK_CARDS:
      'IP Address conflicts with the Network Cards',
    VPN_PROFILE_USES_THIS_POOL: 'Το VPN προφιλ χρησιμοποιει αυτο το Pool',
    PROFILE_NAME_ALREADY_EXISTS: 'Το όνομα του χρήστη υπάρχει ήδη',
    VPN_USER_USES_THIS_PROFILE: 'Ένας χρήστης χρησιμοποιεί αυτό το προφίλ',
    FAILED_TO_FETCH_DATA: 'Αποτυχία λήψης δεδομένων',
    VCENTER_MISMATCH: 'Λάθος αντιστοίχιση VCenter',
    STORAGE_ACCOUNT_IS_PROTECTED: 'Το Storage Account είναι προστατευμένο',
    VPN_CONCENTRATOR_IS_PROTECTED: 'Το VPN Concentrator είναι προστατευμένο',
    REMOTE_ENDPOINT_CANNOT_BE_ASSIGNED_TO_NETWORK_CARD:
      'Το remote endpoint δεν μπορεί να αποδοθεί σε μια κάρτα δικτύου',
    CANNOT_DELETE_LAST_INTERNAL_NETWORK_CARD:
      'Δεν μπορεί να διαγραφεί η τελευταία internal κάρτα δικτύου',
    NO_DELETE_UPLINK_NETWORK_CARD: 'Οχι διαγραφή uplink network card',
    RESOURCE_NAME_ALREADY_EXISTS: 'Το resource name υπάρχει ήδη',
    CANNOT_EDIT_RESOURCE_TYPE:
      'Δεν μπορεί να γίνει επεξεργασία του resource type',
    CANNOT_DELETE_RESOURCE: 'Δεν μπορεί να γίνει διαγραφή του resource',
    LICENSE_INSTANCE_ALREADY_EXISTS: 'Το License instance υπάρχει ήδη',
    LICENSE_INSTANCE_NOT_ACTIVE: 'To License instance δεν είναι ενεργό',
    ADDON_SERVICE_INSTANCE_ALREADY_EXISTS:
      'Το Addon service instance υπάρχει ήδη',
    ADDON_SERVICE_INSTANCE_NOT_ACTIVE:
      'To Addon service instance δεν είναι ενεργό',

    NOT_FOUND: 'Δεν βρέθηκε.',
    INVALID_TOKEN: 'Μη έγκυρο token.',

    SERVER_ERROR: 'Server Error',

    INVALID_ROLE: 'Μη έγκυρος ρόλος.',

    CANNOT_EDIT_USER_STATUS: 'Can not edit user status',
    LEAD_REGISTRATION_VAT_VERIFY_FAILED: 'Lead registration vat verify failed',
    OPERATING_SYSTEM_NOT_AVAILABLE: 'Operationg system not available',
    INVALID_CUSTOMER_ROLE: 'Invalid Customer Role',
    NO_ACCESS_CUSTOMER: 'No access customer',
    INVALID_USER: 'Invalid user',
    NO_DEACTIVATE_SELF: 'Can not deactivate your account',
    NO_EDIT_SELF: 'Can not edit self',
    SYSTEM_ADMIN_ONLY: 'System admin only',

    USER_DEACTIVATED: 'Ο χρήστης Απενεργοποιήθηκε',
    RESOURCE_USAGE_EXCEEDED: 'Υπέρβαση όριο πόρων',
    DISK_EXIST_IN_BACKUPS: 'Ο δίσκος υπάρχει στα αντίγραφα ασφαλείας',
    LOGIN_BLOCKED_RS: 'Η σύνδεση απέτυχε',
    REGISTER_BLOCKED_RS: 'Η εγγραφή απέτυχε',
    S_DENIES_ACCESS: 'Δεν επιτρέπεται η πρόσβαση',
    R_NOT_FOUND: 'Το path δεν βρέθηκε',
    MISSING_OR_EXPIRED_TOKEN: 'Το token λείπει ή έχει λήξει',
    UNAUTHORISED_DISK_IMAGE: 'Δεν έχετε πρόσβαση σε αυτο το image',
    UNAUTHORISED_NETWORK: 'Δεν έχετε πρόσβαση σε αυτο το δίκτυο',
    UNAUTHORISED_CPU_TIER: 'Δεν έχετε πρόσβαση σε αυτο το CPU Tier',
    UNAUTHORISED_LOCATION: 'Δεν έχετε πρόσβαση σε αυτο το Location',
    MISSING_PERMISSION: 'Δεν έχετε άδεια για αυτήν την ενέργεια'
  }
};
