export enum Permissions {
  USER_2FA_ENABLE= 'USER_2FA_ENABLE',
  USER_2FA_DISABLE= 'USER_2FA_DISABLE',
  USER_PROFILE_PASSWORD_CHANGE= 'USER_PROFILE_PASSWORD_CHANGE',
  USER_PROFILE_VIEW= 'USER_PROFILE_VIEW',
  USER_PROFILE_UPDATE= 'USER_PROFILE_UPDATE',
  USER_SESSIONS_DISCONNECT= 'USER_SESSIONS_DISCONNECT',
  USER_SESSIONS_VIEW= 'USER_SESSIONS_VIEW',
  USER_SECURITY_SAFE_DEVICES_DELETE= 'USER_SECURITY_SAFE_DEVICES_DELETE',
  USER_SECURITY_ACCESS_NETWORKS_UPDATE= 'USER_SECURITY_ACCESS_NETWORKS_UPDATE',
  USER_NOTIFICATIONS_UPDATE= 'USER_NOTIFICATIONS_UPDATE',
  USER_NOTIFICATIONS_VIEW= 'USER_NOTIFICATIONS_VIEW',
  USER_LOGS_VIEW= 'USER_LOGS_VIEW',
  USER_CUSTOMERS_VIEW= 'USER_CUSTOMERS_VIEW',
  MANAGEMENT_USER_2FA_ENABLE= 'MANAGEMENT_USER_2FA_ENABLE',
  MANAGEMENT_USER_2FA_DISABLE= 'MANAGEMENT_USER_2FA_DISABLE',
  MANAGEMENT_USER_PROFILE_PASSWORD_CHANGE= 'MANAGEMENT_USER_PROFILE_PASSWORD_CHANGE',
  MANAGEMENT_USER_PROFILE_VIEW= 'MANAGEMENT_USER_PROFILE_VIEW',
  MANAGEMENT_USER_PROFILE_UPDATE= 'MANAGEMENT_USER_PROFILE_UPDATE',
  MANAGEMENT_USER_SESSIONS_DISCONNECT= 'MANAGEMENT_USER_SESSIONS_DISCONNECT',
  MANAGEMENT_USER_SESSIONS_VIEW= 'MANAGEMENT_USER_SESSIONS_VIEW',
  MANAGEMENT_USER_SECURITY_SAFE_DEVICES_DELETE= 'MANAGEMENT_USER_SECURITY_SAFE_DEVICES_DELETE',
  MANAGEMENT_USER_SECURITY_ACCESS_NETWORKS_UPDATE= 'MANAGEMENT_USER_SECURITY_ACCESS_NETWORKS_UPDATE',
  MANAGEMENT_USER_NOTIFICATIONS_UPDATE= 'MANAGEMENT_USER_NOTIFICATIONS_UPDATE',
  MANAGEMENT_USER_NOTIFICATIONS_VIEW= 'MANAGEMENT_USER_NOTIFICATIONS_VIEW',
  MANAGEMENT_USER_LOGS_VIEW= 'MANAGEMENT_USER_LOGS_VIEW',
  CLOUD_CLOUD_SERVER_VIEW= 'CLOUD_CLOUD_SERVER_VIEW',
  CLOUD_CLOUD_SERVER_CREATE= 'CLOUD_CLOUD_SERVER_CREATE',
  CLOUD_CLOUD_SERVER_UPDATE= 'CLOUD_CLOUD_SERVER_UPDATE',
  CLOUD_CLOUD_SERVER_DELETE= 'CLOUD_CLOUD_SERVER_DELETE',
  CLOUD_CLOUD_SERVER_BACKUPS= 'CLOUD_CLOUD_SERVER_BACKUPS',
  CLOUD_CLOUD_SERVER_AUDIT= 'CLOUD_CLOUD_SERVER_AUDIT',
  CLOUD_CLOUD_SERVER_AUTO_AUDIT= 'CLOUD_CLOUD_SERVER_AUTO_AUDIT',
  CLOUD_CLOUD_SERVER_CONSOLE= 'CLOUD_CLOUD_SERVER_CONSOLE',
  CLOUD_CLOUD_SERVER_DISK_IMAGES_MANAGE= 'CLOUD_CLOUD_SERVER_DISK_IMAGES_MANAGE',
  CLOUD_CLOUD_SERVER_DISK_IMAGES_VIEW= 'CLOUD_CLOUD_SERVER_DISK_IMAGES_VIEW',
  CLOUD_CLOUD_SERVER_FIREWALL= 'CLOUD_CLOUD_SERVER_FIREWALL',
  CLOUD_CLOUD_SERVER_GRAPHS= 'CLOUD_CLOUD_SERVER_GRAPHS',
  CLOUD_CLOUD_SERVER_NETWORK_CARDS= 'CLOUD_CLOUD_SERVER_NETWORK_CARDS',
  CLOUD_CLOUD_SERVER_POWER= 'CLOUD_CLOUD_SERVER_POWER',
  CLOUD_CLOUD_SERVER_PROTECTION= 'CLOUD_CLOUD_SERVER_PROTECTION',
  CLOUD_CLOUD_SERVER_REINSTALL= 'CLOUD_CLOUD_SERVER_REINSTALL',
  CLOUD_CLOUD_SERVER_RESCALE= 'CLOUD_CLOUD_SERVER_RESCALE',
  CLOUD_CLOUD_SERVER_RESCUE_MODE= 'CLOUD_CLOUD_SERVER_RESCUE_MODE',
  CLOUD_CLOUD_SERVER_SNAPSHOT= 'CLOUD_CLOUD_SERVER_SNAPSHOT',
  CLOUD_CLOUD_SERVER_VNC= 'CLOUD_CLOUD_SERVER_VNC',
  CLOUD_CLOUD_SERVER_CLONE= 'CLOUD_CLOUD_SERVER_CLONE',
  MANAGEMENT_CLOUD_CLOUD_SERVER_VIEW= 'MANAGEMENT_CLOUD_CLOUD_SERVER_VIEW',
  MANAGEMENT_CLOUD_CLOUD_SERVER_CREATE= 'MANAGEMENT_CLOUD_CLOUD_SERVER_CREATE',
  MANAGEMENT_CLOUD_CLOUD_SERVER_UPDATE= 'MANAGEMENT_CLOUD_CLOUD_SERVER_UPDATE',
  MANAGEMENT_CLOUD_CLOUD_SERVER_DELETE= 'MANAGEMENT_CLOUD_CLOUD_SERVER_DELETE',
  MANAGEMENT_CLOUD_CLOUD_SERVER_BACKUPS= 'MANAGEMENT_CLOUD_CLOUD_SERVER_BACKUPS',
  MANAGEMENT_CLOUD_CLOUD_SERVER_AUDIT= 'MANAGEMENT_CLOUD_CLOUD_SERVER_AUDIT',
  MANAGEMENT_CLOUD_CLOUD_SERVER_CONSOLE= 'MANAGEMENT_CLOUD_CLOUD_SERVER_CONSOLE',
  MANAGEMENT_CLOUD_CLOUD_SERVER_DISK_IMAGES_MANAGE= 'MANAGEMENT_CLOUD_CLOUD_SERVER_DISK_IMAGES_MANAGE',
  MANAGEMENT_CLOUD_CLOUD_SERVER_DISK_IMAGES_VIEW= 'MANAGEMENT_CLOUD_CLOUD_SERVER_DISK_IMAGES_VIEW',
  MANAGEMENT_CLOUD_CLOUD_SERVER_FIREWALL= 'MANAGEMENT_CLOUD_CLOUD_SERVER_FIREWALL',
  MANAGEMENT_CLOUD_CLOUD_SERVER_GRAPHS= 'MANAGEMENT_CLOUD_CLOUD_SERVER_GRAPHS',
  MANAGEMENT_CLOUD_CLOUD_SERVER_NETWORK_CARDS= 'MANAGEMENT_CLOUD_CLOUD_SERVER_NETWORK_CARDS',
  MANAGEMENT_CLOUD_CLOUD_SERVER_NETWORKS= 'MANAGEMENT_CLOUD_CLOUD_SERVER_NETWORKS',
  MANAGEMENT_CLOUD_CLOUD_SERVER_POWER= 'MANAGEMENT_CLOUD_CLOUD_SERVER_POWER',
  MANAGEMENT_CLOUD_CLOUD_SERVER_PROTECTION= 'MANAGEMENT_CLOUD_CLOUD_SERVER_PROTECTION',
  MANAGEMENT_CLOUD_CLOUD_SERVER_REINSTALL= 'MANAGEMENT_CLOUD_CLOUD_SERVER_REINSTALL',
  MANAGEMENT_CLOUD_CLOUD_SERVER_RESCALE= 'MANAGEMENT_CLOUD_CLOUD_SERVER_RESCALE',
  MANAGEMENT_CLOUD_CLOUD_SERVER_RESCUE_MODE= 'MANAGEMENT_CLOUD_CLOUD_SERVER_RESCUE_MODE',
  MANAGEMENT_CLOUD_CLOUD_SERVER_UNMOUNT= 'MANAGEMENT_CLOUD_CLOUD_SERVER_UNMOUNT',
  MANAGEMENT_CLOUD_CLOUD_SERVER_SNAPSHOT= 'MANAGEMENT_CLOUD_CLOUD_SERVER_SNAPSHOT',
  MANAGEMENT_CLOUD_CLOUD_SERVER_VNC= 'MANAGEMENT_CLOUD_CLOUD_SERVER_VNC',
  MANAGEMENT_CLOUD_CLOUD_SERVER_ADMIN= 'MANAGEMENT_CLOUD_CLOUD_SERVER_ADMIN',
  MANAGEMENT_CLOUD_CLOUD_SERVER_CLONE= 'MANAGEMENT_CLOUD_CLOUD_SERVER_CLONE',
  MANAGEMENT_CLOUD_CLOUD_SERVER_POOLS_OPTIONS = 'MANAGEMENT_CLOUD_CLOUD_SERVER_POOLS_OPTIONS',
  CLOUD_ROUTER_CREATE= 'CLOUD_ROUTER_CREATE',
  CLOUD_ROUTER_UPDATE= 'CLOUD_ROUTER_UPDATE',
  CLOUD_ROUTER_DELETE= 'CLOUD_ROUTER_DELETE',
  CLOUD_ROUTER_VIEW= 'CLOUD_ROUTER_VIEW',
  CLOUD_ROUTER_CERTIFICATE= 'CLOUD_ROUTER_CERTIFICATE',
  CLOUD_ROUTER_FIREWALL= 'CLOUD_ROUTER_FIREWALL',
  CLOUD_ROUTER_GRAPHS= 'CLOUD_ROUTER_GRAPHS',
  CLOUD_ROUTER_IPSEC_MANAGE= 'CLOUD_ROUTER_IPSEC_MANAGE',
  CLOUD_ROUTER_IPSEC_VIEW= 'CLOUD_ROUTER_IPSEC_VIEW',
  CLOUD_ROUTER_LOAD_BALANCER= 'CLOUD_ROUTER_LOAD_BALANCER',
  CLOUD_ROUTER_NAT= 'CLOUD_ROUTER_NAT',
  CLOUD_ROUTER_NETWORK_CARD= 'CLOUD_ROUTER_NETWORK_CARD',
  CLOUD_ROUTER_PROTECTION= 'CLOUD_ROUTER_PROTECTION',
  CLOUD_ROUTER_STATIC_ROUTE= 'CLOUD_ROUTER_STATIC_ROUTE',
  MANAGEMENT_CLOUD_ROUTER_CREATE= 'MANAGEMENT_CLOUD_ROUTER_CREATE',
  MANAGEMENT_CLOUD_ROUTER_UPDATE= 'MANAGEMENT_CLOUD_ROUTER_UPDATE',
  MANAGEMENT_CLOUD_ROUTER_DELETE= 'MANAGEMENT_CLOUD_ROUTER_DELETE',
  MANAGEMENT_CLOUD_ROUTER_VIEW= 'MANAGEMENT_CLOUD_ROUTER_VIEW',
  MANAGEMENT_CLOUD_ROUTER_CERTIFICATE= 'MANAGEMENT_CLOUD_ROUTER_CERTIFICATE',
  MANAGEMENT_CLOUD_ROUTER_FIREWALL= 'MANAGEMENT_CLOUD_ROUTER_FIREWALL',
  MANAGEMENT_CLOUD_ROUTER_GRAPHS= 'MANAGEMENT_CLOUD_ROUTER_GRAPHS',
  MANAGEMENT_CLOUD_ROUTER_IPSEC_MANAGE= 'MANAGEMENT_CLOUD_ROUTER_IPSEC_MANAGE',
  MANAGEMENT_CLOUD_ROUTER_IPSEC_VIEW= 'MANAGEMENT_CLOUD_ROUTER_IPSEC_VIEW',
  MANAGEMENT_CLOUD_ROUTER_LOAD_BALANCER= 'MANAGEMENT_CLOUD_ROUTER_LOAD_BALANCER',
  MANAGEMENT_CLOUD_ROUTER_NAT= 'MANAGEMENT_CLOUD_ROUTER_NAT',
  MANAGEMENT_CLOUD_ROUTER_NETWORK_CARD= 'MANAGEMENT_CLOUD_ROUTER_NETWORK_CARD',
  MANAGEMENT_CLOUD_ROUTER_PROTECTION= 'MANAGEMENT_CLOUD_ROUTER_PROTECTION',
  MANAGEMENT_CLOUD_ROUTER_STATIC_ROUTE= 'MANAGEMENT_CLOUD_ROUTER_STATIC_ROUTE',
  MANAGEMENT_CLOUD_ROUTER_PASSWORD_VIEW= 'MANAGEMENT_CLOUD_ROUTER_PASSWORD_VIEW',
  CLOUD_VPN_CREATE= 'CLOUD_VPN_CREATE',
  CLOUD_VPN_UPDATE= 'CLOUD_VPN_UPDATE',
  CLOUD_VPN_DELETE= 'CLOUD_VPN_DELETE',
  CLOUD_VPN_VIEW= 'CLOUD_VPN_VIEW',
  CLOUD_VPN_GRAPHS= 'CLOUD_VPN_GRAPHS',
  CLOUD_VPN_NETWORK_CARDS= 'CLOUD_VPN_NETWORK_CARDS',
  CLOUD_VPN_POOL_MANAGE= 'CLOUD_VPN_POOL_MANAGE',
  CLOUD_VPN_PROFILE= 'CLOUD_VPN_PROFILE',
  CLOUD_VPN_PROTECTION= 'CLOUD_VPN_PROTECTION',
  CLOUD_VPN_STATIC_ROUTES= 'CLOUD_VPN_STATIC_ROUTES',
  CLOUD_VPN_USER_MANAGE= 'CLOUD_VPN_USER_MANAGE',
  CLOUD_VPN_USER_VIEW= 'CLOUD_VPN_USER_VIEW',
  MANAGEMENT_CLOUD_VPN_CREATE= 'MANAGEMENT_CLOUD_VPN_CREATE',
  MANAGEMENT_CLOUD_VPN_UPDATE= 'MANAGEMENT_CLOUD_VPN_UPDATE',
  MANAGEMENT_CLOUD_VPN_DELETE= 'MANAGEMENT_CLOUD_VPN_DELETE',
  MANAGEMENT_CLOUD_VPN_VIEW= 'MANAGEMENT_CLOUD_VPN_VIEW',
  MANAGEMENT_CLOUD_VPN_GRAPHS= 'MANAGEMENT_CLOUD_VPN_GRAPHS',
  MANAGEMENT_CLOUD_VPN_NETWORK_CARDS= 'MANAGEMENT_CLOUD_VPN_NETWORK_CARDS',
  MANAGEMENT_CLOUD_VPN_POOL_MANAGE= 'MANAGEMENT_CLOUD_VPN_POOL_MANAGE',
  MANAGEMENT_CLOUD_VPN_PROFILE= 'MANAGEMENT_CLOUD_VPN_PROFILE',
  MANAGEMENT_CLOUD_VPN_PROTECTION= 'MANAGEMENT_CLOUD_VPN_PROTECTION',
  MANAGEMENT_CLOUD_VPN_STATIC_ROUTES= 'MANAGEMENT_CLOUD_VPN_STATIC_ROUTES',
  MANAGEMENT_CLOUD_VPN_USER_MANAGE= 'MANAGEMENT_CLOUD_VPN_USER_MANAGE',
  MANAGEMENT_CLOUD_VPN_USER_VIEW= 'MANAGEMENT_CLOUD_VPN_USER_VIEW',
  MANAGEMENT_CLOUD_VPN_PASSWORD_VIEW= 'MANAGEMENT_CLOUD_VPN_PASSWORD_VIEW',
  CLOUD_PRIVATE_NETWORK_CREATE= 'CLOUD_PRIVATE_NETWORK_CREATE',
  CLOUD_PRIVATE_NETWORK_MANAGE= 'CLOUD_PRIVATE_NETWORK_MANAGE',
  CLOUD_PRIVATE_NETWORK_DELETE= 'CLOUD_PRIVATE_NETWORK_DELETE',
  CLOUD_PRIVATE_NETWORK_VIEW= 'CLOUD_PRIVATE_NETWORK_VIEW',
  CLOUD_PRIVATE_NETWORK_PROTECTION= 'CLOUD_PRIVATE_NETWORK_PROTECTION',
  CLOUD_PUBLIC_LOCATIONS_CPU_TIERS= 'CLOUD_PUBLIC_LOCATIONS_CPU_TIERS',
  CLOUD_PUBLIC_LOCATIONS_DISK_TYPES= 'CLOUD_PUBLIC_LOCATIONS_DISK_TYPES',
  CLOUD_PUBLIC_LOCATIONS_PUBLIC_NETWORKS= 'CLOUD_PUBLIC_LOCATIONS_PUBLIC_NETWORKS',
  CLOUD_PUBLIC_NETWORK_MANAGE= 'CLOUD_PUBLIC_NETWORK_MANAGE',
  CLOUD_PUBLIC_NETWORK_VIEW= 'CLOUD_PUBLIC_NETWORK_VIEW',
  CLOUD_NETWORKS_VIEW= 'CLOUD_NETWORKS_VIEW',
  MANAGEMENT_CLOUD_NETWORKS_VIEW= 'MANAGEMENT_CLOUD_NETWORKS_VIEW',
  MANAGEMENT_CLOUD_PRIVATE_NETWORK_CREATE= 'MANAGEMENT_CLOUD_PRIVATE_NETWORK_CREATE',
  MANAGEMENT_CLOUD_PRIVATE_NETWORK_DELETE= 'MANAGEMENT_CLOUD_PRIVATE_NETWORK_DELETE',
  MANAGEMENT_CLOUD_PRIVATE_NETWORK_PROTECTION= 'MANAGEMENT_CLOUD_PRIVATE_NETWORK_PROTECTION',
  MANAGEMENT_CLOUD_PRIVATE_NETWORK_MANAGE= 'MANAGEMENT_CLOUD_PRIVATE_NETWORK_MANAGE',
  MANAGEMENT_CLOUD_PRIVATE_NETWORK_VIEW= 'MANAGEMENT_CLOUD_PRIVATE_NETWORK_VIEW',
  MANAGEMENT_CLOUD_PUBLIC_LOCATIONS_CPU_TIERS= 'MANAGEMENT_CLOUD_PUBLIC_LOCATIONS_CPU_TIERS',
  MANAGEMENT_CLOUD_PUBLIC_LOCATIONS_DISK_TYPES= 'MANAGEMENT_CLOUD_PUBLIC_LOCATIONS_DISK_TYPES',
  MANAGEMENT_CLOUD_PUBLIC_LOCATIONS_PUBLIC_NETWORKS= 'MANAGEMENT_CLOUD_PUBLIC_LOCATIONS_PUBLIC_NETWORKS',
  MANAGEMENT_CLOUD_PUBLIC_NETWORK_MANAGE= 'MANAGEMENT_CLOUD_PUBLIC_NETWORK_MANAGE',
  MANAGEMENT_CLOUD_PUBLIC_NETWORK_CREATE= 'MANAGEMENT_CLOUD_PUBLIC_NETWORK_CREATE',
  MANAGEMENT_CLOUD_PUBLIC_NETWORK_DELETE= 'MANAGEMENT_CLOUD_PUBLIC_NETWORK_DELETE',
  MANAGEMENT_CLOUD_PUBLIC_NETWORK_VIEW= 'MANAGEMENT_CLOUD_PUBLIC_NETWORK_VIEW',
  CLOUD_STORAGE_CREATE= 'CLOUD_STORAGE_CREATE',
  CLOUD_STORAGE_UPDATE= 'CLOUD_STORAGE_UPDATE',
  CLOUD_STORAGE_DELETE= 'CLOUD_STORAGE_DELETE',
  CLOUD_STORAGE_VIEW= 'CLOUD_STORAGE_VIEW',
  CLOUD_STORAGE_PROTECTION_MANAGE= 'CLOUD_STORAGE_PROTECTION_MANAGE',
  CLOUD_STORAGE_FILE_MANAGER= 'CLOUD_STORAGE_FILE_MANAGER',
  MANAGEMENT_CLOUD_STORAGE_CREATE= 'MANAGEMENT_CLOUD_STORAGE_CREATE',
  MANAGEMENT_CLOUD_STORAGE_UPDATE= 'MANAGEMENT_CLOUD_STORAGE_UPDATE',
  MANAGEMENT_CLOUD_STORAGE_DELETE= 'MANAGEMENT_CLOUD_STORAGE_DELETE',
  MANAGEMENT_CLOUD_STORAGE_VIEW= 'MANAGEMENT_CLOUD_STORAGE_VIEW',
  MANAGEMENT_CLOUD_STORAGE_PROTECTION_MANAGE= 'MANAGEMENT_CLOUD_STORAGE_PROTECTION_MANAGE',
  MANAGEMENT_CLOUD_STORAGE_FILE_MANAGER= 'MANAGEMENT_CLOUD_STORAGE_FILE_MANAGER',
  CLOUD_CLOUD_DISK_CREATE= 'CLOUD_CLOUD_DISK_CREATE',
  CLOUD_CLOUD_DISK_DELETE= 'CLOUD_CLOUD_DISK_DELETE',
  CLOUD_CLOUD_DISK_MANAGE= 'CLOUD_CLOUD_DISK_MANAGE',
  CLOUD_CLOUD_DISK_VIEW= 'CLOUD_CLOUD_DISK_VIEW',
  MANAGEMENT_CLOUD_CLOUD_DISK_CREATE= 'MANAGEMENT_CLOUD_CLOUD_DISK_CREATE',
  MANAGEMENT_CLOUD_CLOUD_DISK_DELETE= 'MANAGEMENT_CLOUD_CLOUD_DISK_DELETE',
  MANAGEMENT_CLOUD_CLOUD_DISK_MANAGE= 'MANAGEMENT_CLOUD_CLOUD_DISK_MANAGE',
  MANAGEMENT_CLOUD_CLOUD_DISK_VIEW= 'MANAGEMENT_CLOUD_CLOUD_DISK_VIEW',
  CLOUD_IP_ADDRESS_MANAGE= 'CLOUD_IP_ADDRESS_MANAGE',
  CLOUD_IP_ADDRESS_VIEW= 'CLOUD_IP_ADDRESS_VIEW',
  MANAGEMENT_CLOUD_IP_BLOCK_CREATE= 'MANAGEMENT_CLOUD_IP_BLOCK_CREATE',
  MANAGEMENT_CLOUD_IP_BLOCK_DELETE= 'MANAGEMENT_CLOUD_IP_BLOCK_DELETE',
  MANAGEMENT_CLOUD_IP_BLOCK_MANAGE= 'MANAGEMENT_CLOUD_IP_BLOCK_MANAGE',
  MANAGEMENT_CLOUD_IP_BLOCK_VIEW= 'MANAGEMENT_CLOUD_IP_BLOCK_VIEW',
  CLOUD_ADDON_SERVICES_MANAGE= 'CLOUD_ADDON_SERVICES_MANAGE',
  CLOUD_ADDON_SERVICES_VIEW= 'CLOUD_ADDON_SERVICES_VIEW',
  CLOUD_LICENSES_VIEW= 'CLOUD_LICENSES_VIEW',
  CLOUD_LICENSES_MANAGE= 'CLOUD_LICENSES_MANAGE',
  CLOUD_SSH_KEY_CREATE= 'CLOUD_SSH_KEY_CREATE',
  CLOUD_SSH_KEY_UPDATE= 'CLOUD_SSH_KEY_UPDATE',
  CLOUD_SSH_KEY_DELETE= 'CLOUD_SSH_KEY_DELETE',
  CLOUD_SSH_KEY_VIEW= 'CLOUD_SSH_KEY_VIEW',
  MANAGEMENT_CLOUD_SSH_KEY_CREATE= 'MANAGEMENT_CLOUD_SSH_KEY_CREATE',
  MANAGEMENT_CLOUD_SSH_KEY_UPDATE= 'MANAGEMENT_CLOUD_SSH_KEY_UPDATE',
  MANAGEMENT_CLOUD_SSH_KEY_DELETE= 'MANAGEMENT_CLOUD_SSH_KEY_DELETE',
  MANAGEMENT_CLOUD_SSH_KEY_VIEW= 'MANAGEMENT_CLOUD_SSH_KEY_VIEW',
  CLOUD_DNS_ZONE_VIEW= 'CLOUD_DNS_ZONE_VIEW',
  CLOUD_DNS_ZONE_CREATE= 'CLOUD_DNS_ZONE_CREATE',
  CLOUD_DNS_ZONE_MANAGE= 'CLOUD_DNS_ZONE_MANAGE',
  CLOUD_DNS_ZONE_DELETE= 'CLOUD_DNS_ZONE_DELETE',
  CLOUD_DNS_ZONE_PROTECTION= 'CLOUD_DNS_ZONE_PROTECTION',
  MANAGEMENT_CLOUD_DNS_ZONE_GRAPHS= 'MANAGEMENT_CLOUD_DNS_ZONE_GRAPHS',
  CLOUD_DNS_ZONE_GRAPHS= 'CLOUD_DNS_ZONE_GRAPHS',
  MANAGEMENT_CLOUD_DNS_ZONE_VIEW= 'MANAGEMENT_CLOUD_DNS_ZONE_VIEW',
  MANAGEMENT_CLOUD_DNS_ZONE_CREATE= 'MANAGEMENT_CLOUD_DNS_ZONE_CREATE',
  MANAGEMENT_CLOUD_DNS_ZONE_MANAGE= 'MANAGEMENT_CLOUD_DNS_ZONE_MANAGE',
  MANAGEMENT_CLOUD_DNS_ZONE_DELETE= 'MANAGEMENT_CLOUD_DNS_ZONE_DELETE',
  MANAGEMENT_CLOUD_DNS_ZONE_PROTECTION= 'MANAGEMENT_CLOUD_DNS_ZONE_PROTECTION',
  CLOUD_DATACENTER_VIEW= 'CLOUD_DATACENTER_VIEW',
  MANAGEMENT_CLOUD_DATACENTER_VIEW= 'MANAGEMENT_CLOUD_DATACENTER_VIEW',
  MANAGEMENT_CLOUD_DATACENTER_CREATE= 'MANAGEMENT_CLOUD_DATACENTER_CREATE',
  MANAGEMENT_CLOUD_DATACENTER_MANAGE= 'MANAGEMENT_CLOUD_DATACENTER_MANAGE',
  MANAGEMENT_CLOUD_DATACENTER_DELETE= 'MANAGEMENT_CLOUD_DATACENTER_DELETE',
  CUSTOMER_DASHBOARD_VIEW= 'CUSTOMER_DASHBOARD_VIEW',
  CUSTOMER_VIEW= 'CUSTOMER_VIEW',
  CUSTOMER_LEADS_VIEW= 'CUSTOMER_LEADS_VIEW',
  CUSTOMER_LEADS_MANAGE= 'CUSTOMER_LEADS_MANAGE',
  CUSTOMER_TASKS_VIEW= 'CUSTOMER_TASKS_VIEW',
  CUSTOMER_BILLING_INVOICE= 'CUSTOMER_BILLING_INVOICE',
  CUSTOMER_BILLING_ORDER_VIEW= 'CUSTOMER_BILLING_ORDER_VIEW',
  CUSTOMER_BILLING_ORDER_PRICING= 'CUSTOMER_BILLING_ORDER_PRICING',
  CUSTOMER_BILLING_OVERVIEW= 'CUSTOMER_BILLING_OVERVIEW',
  CUSTOMER_CONFIG_VIEW= 'CUSTOMER_CONFIG_VIEW',
  CUSTOMER_MANAGE= 'CUSTOMER_MANAGE',
  CUSTOMER_PRICING_VIEW= 'CUSTOMER_PRICING_VIEW',
  CUSTOMER_INSTANCES_VIEW= 'CUSTOMER_INSTANCES_VIEW',
  CUSTOMER_USAGE_VIEW= 'CUSTOMER_USAGE_VIEW',
  CUSTOMER_USERS_VIEW= 'CUSTOMER_USERS_VIEW',
  CUSTOMER_USERS_MANAGE= 'CUSTOMER_USERS_MANAGE',
  CUSTOMER_PUBLIC_LOCATIONS_VIEW= 'CUSTOMER_PUBLIC_LOCATIONS_VIEW',
  CUSTOMER_PRIVATE_LOCATIONS_VIEW= 'CUSTOMER_PRIVATE_LOCATIONS_VIEW',
  CUSTOMER_SEARCH_INSTANCES= 'CUSTOMER_SEARCH_INSTANCES',
  CUSTOMER_INSTANCE_EXPORT= 'CUSTOMER_INSTANCE_EXPORT',
  CUSTOMER_BILLING_TRANSACTIONS= 'CUSTOMER_BILLING_TRANSACTIONS',
  MANAGEMENT_HELP_REPORTS_DELETE= 'MANAGEMENT_HELP_REPORTS_DELETE',
  MANAGEMENT_HELP_REPORTS_VIEW= 'MANAGEMENT_HELP_REPORTS_VIEW',
  MANAGEMENT_CUSTOMER_VIEW= 'MANAGEMENT_CUSTOMER_VIEW',
  MANAGEMENT_CUSTOMER_LEADS_VIEW= 'MANAGEMENT_CUSTOMER_LEADS_VIEW',
  MANAGEMENT_CUSTOMER_LEADS_MANAGE= 'MANAGEMENT_CUSTOMER_LEADS_MANAGE',
  MANAGEMENT_CUSTOMER_TASKS_VIEW= 'MANAGEMENT_CUSTOMER_TASKS_VIEW',
  MANAGEMENT_CUSTOMER_BILLING_INVOICE= 'MANAGEMENT_CUSTOMER_BILLING_INVOICE',
  MANAGEMENT_CUSTOMER_BILLING_ORDER_VIEW= 'MANAGEMENT_CUSTOMER_BILLING_ORDER_VIEW',
  MANAGEMENT_CUSTOMER_BILLING_ORDER_PRICING= 'MANAGEMENT_CUSTOMER_BILLING_ORDER_PRICING',
  MANAGEMENT_CUSTOMER_BILLING_OVERVIEW= 'MANAGEMENT_CUSTOMER_BILLING_OVERVIEW',
  MANAGEMENT_CUSTOMER_CONFIG_VIEW= 'MANAGEMENT_CUSTOMER_CONFIG_VIEW',
  MANAGEMENT_CUSTOMER_DASHBOARD_VIEW= 'MANAGEMENT_CUSTOMER_DASHBOARD_VIEW',
  MANAGEMENT_CUSTOMER_PRICING_VIEW= 'MANAGEMENT_CUSTOMER_PRICING_VIEW',
  MANAGEMENT_CUSTOMER_USAGE_VIEW= 'MANAGEMENT_CUSTOMER_USAGE_VIEW',
  MANAGEMENT_CUSTOMER_USERS_VIEW= 'MANAGEMENT_CUSTOMER_USERS_VIEW',
  MANAGEMENT_CUSTOMER_USERS_MANAGE= 'MANAGEMENT_CUSTOMER_USERS_MANAGE',
  MANAGEMENT_CUSTOMER_USERS_REMOVE= 'MANAGEMENT_CUSTOMER_USERS_REMOVE',
  MANAGEMENT_CUSTOMER_PUBLIC_LOCATIONS_VIEW= 'MANAGEMENT_CUSTOMER_PUBLIC_LOCATIONS_VIEW',
  MANAGEMENT_CUSTOMER_PRIVATE_LOCATIONS_VIEW= 'MANAGEMENT_CUSTOMER_PRIVATE_LOCATIONS_VIEW',
  MANAGEMENT_CUSTOMER_SEARCH_INSTANCES= 'MANAGEMENT_CUSTOMER_SEARCH_INSTANCES',
  MANAGEMENT_CUSTOMER_INSTANCE_EXPORT= 'MANAGEMENT_CUSTOMER_INSTANCE_EXPORT',
  MANAGEMENT_VMWARE_IMPORT_REPORT= 'MANAGEMENT_VMWARE_IMPORT_REPORT',
  MANAGEMENT_CUSTOMER_FEATURE_FLAGS= 'MANAGEMENT_CUSTOMER_FEATURE_FLAGS',
  MANAGEMENT_CLOUD_ADDON_SERVICES_MANAGE= 'MANAGEMENT_CLOUD_ADDON_SERVICES_MANAGE',
  MANAGEMENT_CLOUD_ADDON_SERVICES_CREATE= 'MANAGEMENT_CLOUD_ADDON_SERVICES_CREATE',
  MANAGEMENT_CLOUD_ADDON_SERVICES_DELETE= 'MANAGEMENT_CLOUD_ADDON_SERVICES_DELETE',
  MANAGEMENT_CLOUD_ADDON_SERVICES_VIEW= 'MANAGEMENT_CLOUD_ADDON_SERVICES_VIEW',
  MANAGEMENT_CLOUD_BACKUP_SERVERS_CREATE= 'MANAGEMENT_CLOUD_BACKUP_SERVERS_CREATE',
  MANAGEMENT_CLOUD_BACKUP_SERVERS_UPDATE= 'MANAGEMENT_CLOUD_BACKUP_SERVERS_UPDATE',
  MANAGEMENT_CLOUD_BACKUP_SERVERS_DELETE= 'MANAGEMENT_CLOUD_BACKUP_SERVERS_DELETE',
  MANAGEMENT_CLOUD_BACKUP_SERVERS_VIEW= 'MANAGEMENT_CLOUD_BACKUP_SERVERS_VIEW',
  MANAGEMENT_CLOUD_CLOUD_SERVER_AUTO_AUDIT= 'MANAGEMENT_CLOUD_CLOUD_SERVER_AUTO_AUDIT',
  MANAGEMENT_CLOUD_CPU_TIERS_VIEW= 'MANAGEMENT_CLOUD_CPU_TIERS_VIEW',
  MANAGEMENT_CLOUD_CPU_TIERS_CREATE= 'MANAGEMENT_CLOUD_CPU_TIERS_CREATE',
  MANAGEMENT_CLOUD_CPU_TIERS_MANAGE= 'MANAGEMENT_CLOUD_CPU_TIERS_MANAGE',
  MANAGEMENT_HELP_TOPICS_MANAGE= 'MANAGEMENT_HELP_TOPICS_MANAGE',
  MANAGEMENT_HELP_TOPICS_CREATE= 'MANAGEMENT_HELP_TOPICS_CREATE',
  MANAGEMENT_HELP_TOPICS_DELETE= 'MANAGEMENT_HELP_TOPICS_DELETE',
  MANAGEMENT_HELP_TOPICS_VIEW= 'MANAGEMENT_HELP_TOPICS_VIEW',
  MANAGEMENT_CLOUD_CLOUD_DISK_IMAGES_VIEW= 'MANAGEMENT_CLOUD_CLOUD_DISK_IMAGES_VIEW',
  MANAGEMENT_CLOUD_CLOUD_DISK_IMAGES_MANAGE= 'MANAGEMENT_CLOUD_CLOUD_DISK_IMAGES_MANAGE',
  MANAGEMENT_CLOUD_CLOUD_DISK_IMAGES_CREATE= 'MANAGEMENT_CLOUD_CLOUD_DISK_IMAGES_CREATE',
  MANAGEMENT_CLOUD_CLOUD_DISK_IMAGES_DELETE= 'MANAGEMENT_CLOUD_CLOUD_DISK_IMAGES_DELETE',
  MANAGEMENT_CLOUD_CLOUD_DISK_TYPES_VIEW= 'MANAGEMENT_CLOUD_CLOUD_DISK_TYPES_VIEW',
  MANAGEMENT_CLOUD_CLOUD_DISK_TYPES_MANAGE= 'MANAGEMENT_CLOUD_CLOUD_DISK_TYPES_MANAGE',
  MANAGEMENT_CLOUD_CLOUD_DISK_TYPES_CREATE= 'MANAGEMENT_CLOUD_CLOUD_DISK_TYPES_CREATE',
  MANAGEMENT_CLOUD_CLOUD_DISK_TYPES_DELETE= 'MANAGEMENT_CLOUD_CLOUD_DISK_TYPES_DELETE',
  MANAGEMENT_CLOUD_IP_ADDRESS_VIEW_BANDWIDTH= 'MANAGEMENT_CLOUD_IP_ADDRESS_VIEW_BANDWIDTH',
  MANAGEMENT_CLOUD_IP_ADDRESS_VIEW= 'MANAGEMENT_CLOUD_IP_ADDRESS_VIEW',
  MANAGEMENT_CLOUD_IP_ADDRESS_MANAGE= 'MANAGEMENT_CLOUD_IP_ADDRESS_MANAGE',
  MANAGEMENT_CLOUD_LICENSES_VIEW= 'MANAGEMENT_CLOUD_LICENSES_VIEW',
  MANAGEMENT_CLOUD_LICENSES_MANAGE= 'MANAGEMENT_CLOUD_LICENSES_MANAGE',
  MANAGEMENT_CLOUD_LICENSES_CREATE= 'MANAGEMENT_CLOUD_LICENSES_CREATE',
  MANAGEMENT_CLOUD_LICENSES_DELETE= 'MANAGEMENT_CLOUD_LICENSES_DELETE',
  MANAGEMENT_VMWARE_DATASTORES_VIEW= 'MANAGEMENT_VMWARE_DATASTORES_VIEW',
  MANAGEMENT_VMWARE_HOSTS_VIEW= 'MANAGEMENT_VMWARE_HOSTS_VIEW',
  MANAGEMENT_VMWARE_RESOURCE_POOLS_VIEW= 'MANAGEMENT_VMWARE_RESOURCE_POOLS_VIEW',
  MANAGEMENT_VMWARE_VCENTER_CREATE= 'MANAGEMENT_VMWARE_VCENTER_CREATE',
  MANAGEMENT_VMWARE_VCENTER_MANAGE= 'MANAGEMENT_VMWARE_VCENTER_MANAGE',
  MANAGEMENT_VMWARE_VCENTER_VIEW= 'MANAGEMENT_VMWARE_VCENTER_VIEW',
  MANAGEMENT_CUSTOMER_CREATE= 'MANAGEMENT_CUSTOMER_CREATE',
  MANAGEMENT_CUSTOMER_MANAGE= 'MANAGEMENT_CUSTOMER_MANAGE',
  MANAGEMENT_CUSTOMER_BILLING_VIEW= 'MANAGEMENT_CUSTOMER_BILLING_VIEW',
  MANAGEMENT_CUSTOMER_FINANCIAL_MANAGE= 'MANAGEMENT_CUSTOMER_FINANCIAL_MANAGE',
  MANAGEMENT_CUSTOMER_PRICING_MANAGE= 'MANAGEMENT_CUSTOMER_PRICING_MANAGE',
  MANAGEMENT_CUSTOMER_RESOURCES_VIEW= 'MANAGEMENT_CUSTOMER_RESOURCES_VIEW',
  MANAGEMENT_CUSTOMER_RESOURCES_MANAGE= 'MANAGEMENT_CUSTOMER_RESOURCES_MANAGE',
  MANAGEMENT_CUSTOMER_SALES_MANAGE= 'MANAGEMENT_CUSTOMER_SALES_MANAGE',
  MANAGEMENT_CUSTOMER_TECHNICAL_MANAGE= 'MANAGEMENT_CUSTOMER_TECHNICAL_MANAGE',
  MANAGEMENT_CUSTOMERS_ORDERS_VIEW= 'MANAGEMENT_CUSTOMERS_ORDERS_VIEW',
  MANAGEMENT_CUSTOMERS_ORDERS_MANAGE= 'MANAGEMENT_CUSTOMERS_ORDERS_MANAGE',
  MANAGEMENT_PRODUCT_CATEGORIES_VIEW= 'MANAGEMENT_PRODUCT_CATEGORIES_VIEW',
  MANAGEMENT_PRODUCT_CATEGORIES_MANAGE= 'MANAGEMENT_PRODUCT_CATEGORIES_MANAGE',
  MANAGEMENT_PRODUCT_CATEGORIES_CREATE= 'MANAGEMENT_PRODUCT_CATEGORIES_CREATE',
  MANAGEMENT_PRODUCT_CATEGORIES_DELETE= 'MANAGEMENT_PRODUCT_CATEGORIES_DELETE',
  MANAGEMENT_PRODUCTS_VIEW= 'MANAGEMENT_PRODUCTS_VIEW',
  MANAGEMENT_PRODUCTS_MANAGE= 'MANAGEMENT_PRODUCTS_MANAGE',
  MANAGEMENT_PRODUCTS_CREATE= 'MANAGEMENT_PRODUCTS_CREATE',
  MANAGEMENT_PRODUCTS_DELETE= 'MANAGEMENT_PRODUCTS_DELETE',
  MANAGEMENT_RESOURCE_GROUPS_VIEW= 'MANAGEMENT_RESOURCE_GROUPS_VIEW',
  MANAGEMENT_RESOURCE_GROUPS_MANAGE= 'MANAGEMENT_RESOURCE_GROUPS_MANAGE',
  MANAGEMENT_RESOURCE_GROUPS_CREATE= 'MANAGEMENT_RESOURCE_GROUPS_CREATE',
  MANAGEMENT_RESOURCE_GROUPS_DELETE= 'MANAGEMENT_RESOURCE_GROUPS_DELETE',
  MANAGEMENT_RESOURCES_VIEW= 'MANAGEMENT_RESOURCES_VIEW',
  MANAGEMENT_RESOURCES_MANAGE= 'MANAGEMENT_RESOURCES_MANAGE',
  MANAGEMENT_RESOURCES_CREATE= 'MANAGEMENT_RESOURCES_CREATE',
  MANAGEMENT_RESOURCES_DELETE= 'MANAGEMENT_RESOURCES_DELETE',
  MANAGEMENT_CUSTOMER_BILLING_TRANSACTIONS= 'MANAGEMENT_CUSTOMER_BILLING_TRANSACTIONS',
  MANAGEMENT_SYSTEM_OPERATIONS= 'MANAGEMENT_SYSTEM_OPERATIONS',
  MANAGEMENT_CLOUD_DNS_CLUSTER_CREATE= 'MANAGEMENT_CLOUD_DNS_CLUSTER_CREATE',
  MANAGEMENT_CLOUD_DNS_CLUSTER_DELETE= 'MANAGEMENT_CLOUD_DNS_CLUSTER_DELETE',
  MANAGEMENT_CLOUD_DNS_CLUSTER_MANAGE= 'MANAGEMENT_CLOUD_DNS_CLUSTER_MANAGE',
  MANAGEMENT_CLOUD_DNS_CLUSTER_VIEW= 'MANAGEMENT_CLOUD_DNS_CLUSTER_VIEW',
  MANAGEMENT_CLOUD_DNS_SERVER_CREATE= 'MANAGEMENT_CLOUD_DNS_SERVER_CREATE',
  MANAGEMENT_CLOUD_DNS_SERVER_DELETE= 'MANAGEMENT_CLOUD_DNS_SERVER_DELETE',
  MANAGEMENT_CLOUD_DNS_SERVER_MANAGE= 'MANAGEMENT_CLOUD_DNS_SERVER_MANAGE',
  MANAGEMENT_CLOUD_DNS_SERVER_VIEW= 'MANAGEMENT_CLOUD_DNS_SERVER_VIEW',
  MANAGEMENT_CUSTOMER_INSTANCES_VIEW= 'MANAGEMENT_CUSTOMER_INSTANCES_VIEW',
  MANAGEMENT_MAINTENANCE_MODE_BYPASS= 'MANAGEMENT_MAINTENANCE_MODE_BYPASS',
  CLOUD_PRIVATE_LOCATIONS_CPU_TIERS= 'CLOUD_PRIVATE_LOCATIONS_CPU_TIERS',
  CLOUD_PRIVATE_LOCATIONS_DISK_TYPES= 'CLOUD_PRIVATE_LOCATIONS_DISK_TYPES',
  CLOUD_PRIVATE_LOCATIONS_PUBLIC_NETWORKS= 'CLOUD_PRIVATE_LOCATIONS_PUBLIC_NETWORKS',
  MANAGEMENT_CLOUD_PRIVATE_LOCATIONS_CPU_TIERS= 'MANAGEMENT_CLOUD_PRIVATE_LOCATIONS_CPU_TIERS',
  MANAGEMENT_CLOUD_PRIVATE_LOCATIONS_DISK_TYPES= 'MANAGEMENT_CLOUD_PRIVATE_LOCATIONS_DISK_TYPES',
  MANAGEMENT_CLOUD_PRIVATE_LOCATIONS_PUBLIC_NETWORKS= 'MANAGEMENT_CLOUD_PRIVATE_LOCATIONS_PUBLIC_NETWORKS',
  MANAGEMENT_ICONS_VIEW= 'MANAGEMENT_ICONS_VIEW',
  MANAGEMENT_ICONS_MANAGE= 'MANAGEMENT_ICONS_MANAGE'
}