import { Dark } from 'quasar';
import { boot } from 'quasar/wrappers';

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $isDark: () => boolean;
  }
}

export default boot(({ app }) => {
  app.config.globalProperties.$isDark = function() {
    return Dark.isActive;
  };
});
