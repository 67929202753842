import { setCssVar } from 'quasar'
import { boot } from 'quasar/wrappers'
import { axios } from '@/boot/axios';
import { AxiosResponse } from 'axios';
import { Branding } from '@/models/Types';

import { branding, getFallbackFavicon } from '@/commons/branding';

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $BRANDING: Branding
  }
}
export default boot(async ({ app }) => {
  await axios.get('/config/brand').then((response: AxiosResponse) => {
    const data = response.data as Branding
    for (const key in data) {
      // eslint-disable-next-line no-prototype-builtins
      if (data.hasOwnProperty(key)) {
        branding.value[key] = data[key]
      }
    }
  })
  app.config.globalProperties.$BRANDING = branding.value

  if (branding.value){
    const brandColor = branding.value.BRAND_COLOR || '#1a651b'
    setCssVar('primary', brandColor)
    if(branding.value.BRAND_NAME){
      document.title = branding.value.BRAND_NAME || 'Panel'
    }
    
    const svg = getFallbackFavicon(brandColor)
    const blob = new Blob([svg], { type: 'image/svg+xml' });
    const url = URL.createObjectURL(blob);

    const oldFavicon = document.getElementById('favicon')
    const link = document.createElement('link')
    link.id = 'favicon';
    link.type = 'image/x-icon'
    link.rel = 'icon';
    link.href = branding.value.BRAND_FAVICON || url;
    if (oldFavicon) {
        document.head.removeChild(oldFavicon);
    }
    document.head.appendChild(link);
  }
})