import { createPinia } from 'pinia';
import { boot } from 'quasar/wrappers';
import { appStore } from '@/store/app';

export default boot(({ app }) => {
  app.use(createPinia());

  const App = appStore();
  App.loadConfig();
});
