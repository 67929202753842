//TODO replace all store calls with pinia store

import { appStore } from '@/store/app';
import { notificationsStore } from '@/store/notifications';
import { userStore } from '@/store/user';
import { AxiosError, AxiosInstance } from 'axios';
import { default as Axios } from 'axios';
// import { Router } from 'src/router';
import { boot } from 'quasar/wrappers';

import { i18n } from '../boot/i18n';

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $axios: AxiosInstance;
  }
}

export interface ServerResponse extends AxiosError {
  error: string;
  message: string;
}
const publicRoutes = [
  '/login',
  '/register',
  '/reset-password',
  '/forgot-password'
];

export const axios = Axios.create({
  baseURL: process.env.api,
  withCredentials: true
});

export default boot(({ app }) => {
  const AppStore = appStore();
  const user = userStore();
  const notifications = notificationsStore();
  axios.interceptors.response.use(
    response => {
      return response;
    },
    (error: AxiosError) => {
      const path = window.location.pathname;
      let showNotification = true;
      if (
        error.response &&
        error.response.status === 401 &&
        publicRoutes.indexOf(path) === -1
      ) {
        console.warn('Session expired');
        if (user.getUser?.id) {
          AppStore.openLoginModal();
        }
        showNotification = false;
      } else if (
        error.response &&
        error.response.status === 401 &&
        (error.response.data as ServerResponse).error === 'UNAUTHENTICATED'
      ) {
        showNotification = false;
      } else if (
        error.response &&
        error.response.status === 403 &&
        (error.response.data as ServerResponse).error ===
          'REQUIRES_PASSWORD_CHANGE'
      ) {
        showNotification = false;
      } else if (
        error.response &&
        error.response.data &&
        (error.response.data as ServerResponse).error === 'AUTH_ERROR'
      ) {
        showNotification = false;
      } else {
        console.error(error);
      }

      if (showNotification) {
        if (
          error.response &&
          error.response.data &&
          (error.response.data as ServerResponse).message
        ) {
          //todo check if exists in translation
          notifications.notify({
            color: 'negative',
            text: i18n.global.t(
              'enums.tasks.error.' + error.response.data.message,
              {
                field: error.response.data.field
              }
            )
          });
        } else if (
          error.response &&
          error.response.data &&
          (error.response.data as ServerResponse).error
        ) {
          notifications.notify({
            color: 'negative',
            text: i18n.global.t(
              'enums.tasks.error.' + error.response.data.error
            )
          });
        } else {
          notifications.notify({
            color: 'negative',
            text: error.toString()
          });
        }
      }

      return Promise.reject(error);
    }
  );

  app.config.globalProperties.$axios = axios;
  // ^ ^ ^ this will allow you to use this.$axios (for Vue Options API form)
  //       so you won't necessarily have to import axios in each vue file

  app.config.globalProperties.$api = axios;
  // ^ ^ ^ this will allow you to use this.$api (for Vue Options API form)
  //       so you can easily perform requests against your app's API
});
