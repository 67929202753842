// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { Permissions } from '@/commons/Permissions'
import { appStore } from '@/store/app'
import { notificationsStore } from '@/store/notifications'
import { userStore } from '@/store/user'
import { AxiosError } from 'axios'
import { computed } from 'vue'
import { NavigationGuardWithThis } from 'vue-router'

export const permissionGuard = (
  keys: (keyof typeof Permissions)[]
): NavigationGuardWithThis<undefined> => {
  return async (to, from, next) => {
    const User = userStore();
    const user = computed(() => User.getUser);
    const customerId = computed(() => User.getCustomerId);

    const globalPermissions = user.value.global_permissions;
    if (keys.some(key => globalPermissions[key])) {
      next()
      return
    }

    const customerPermissions = user.value.permissions[Number(customerId.value)];
    if (customerPermissions && keys.some(key => customerPermissions[key])) {
      next()
    } else {
      next('/no-access')
    }
  }
}

export const appInitializationGuard: NavigationGuardWithThis<undefined> = async (to, from, next) => {
  // eslint-disable-next-line no-console
  if (process.env.DEBUG_LOGS === 'TRUE') console.log('kanw koumanta')
  const User = userStore();
  await User.loadProfile()
    .then(() => {
      const notifications = notificationsStore();
      User.getCustomerId && notifications.subscribe(User.getCustomerId);
      const app = appStore();
      app.closeLoginModal();

      User.user.customers.map(customer => {
        if (
          (customer.requires_twofa || app.config.USER_REQUIRE_TWOFA) &&
          User.user.twofa_status !== 'ENABLED'
        ) {
          next('/two-factor-enable');
        }
      });
      return next();
    })
    .catch((error: AxiosError) => {
      if (error.response && error.response.status === 401) {
        next({ path: '/login' });
      } else {
        next({ path: '/Error' });
      }
    });
}

export const customerGuard: NavigationGuardWithThis<undefined> = (to, from, next) => {
  const User = userStore();
  const user = computed(() => User.getUser);
  const globalPermissions = user.value.global_permissions
  const customerPermissions = user.value.permissions

  const globalAccess = globalPermissions[Permissions.MANAGEMENT_CUSTOMER_VIEW]

  const customerId = to.params.id
  let customerAccess
  if (customerId && !Array.isArray(customerId)){
    customerAccess = customerPermissions[customerId][Permissions.CUSTOMER_VIEW]
  }
  if (globalAccess || customerAccess) {
    next()
  } else {
    next('/no-access')
  }
}

export const userGuard: NavigationGuardWithThis<undefined> = (to, from, next) => {
  const User = userStore();
  const user = computed(() => User.getUser);
  const globalPermissions = user.value.global_permissions

  const managerAccess = globalPermissions[Permissions.MANAGEMENT_USER_PROFILE_VIEW]
  const userAccess = globalPermissions[Permissions.USER_PROFILE_VIEW]
  if (managerAccess) {
    next()
  } else if (userAccess && user.value.id == to.params.id) {
    next()
  } else {
    next('/no-access')
  }
}