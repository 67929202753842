import { permissionGuard, appInitializationGuard, customerGuard, userGuard } from '@/middleware/guards';
import { Permissions } from '@/commons/Permissions';
// import { computed } from 'vue';
// import { appStore } from '@/store/app';

// const app = appStore();
// const config = computed(() => app.getConfig);
const routes = [
  {
    path: '/',
    beforeEnter: [appInitializationGuard],
    component: () => import('@/layouts/AuthLayout.vue'),
    children: [
      {
        path: '',
        // permissionGuard([Permissions.MANAGEMENT_CUSTOMER_DASHBOARD_VIEW, Permissions.CUSTOMER_DASHBOARD_VIEW])
        beforeEnter: [],
        component: () => import('@/pages/Dashboard.vue'),
        meta: { title: 'tabs.dashboard' }
      },
      {
        path: '/customer/:id',
        beforeEnter: [customerGuard],
        component: () => import('@/pages/CustomerPage.vue')
      },
      { 
        path: '/servers', 
        beforeEnter: [permissionGuard([Permissions.MANAGEMENT_CLOUD_CLOUD_SERVER_VIEW, Permissions.CLOUD_CLOUD_SERVER_VIEW])],
        component: () => import('@/pages/Servers.vue'),
        meta: { title: 'tabs.servers' }
      },
      {
        path: '/servers/create',
        beforeEnter: [permissionGuard([Permissions.MANAGEMENT_CLOUD_CLOUD_SERVER_CREATE, Permissions.CLOUD_CLOUD_SERVER_CREATE])],
        component: () => import('pages/ServerCreate.vue'),
        meta: { title: 'tabs.create_server' }
      },
      { 
        path: '/servers/:id', 
        beforeEnter: [permissionGuard([Permissions.MANAGEMENT_CLOUD_CLOUD_SERVER_VIEW, Permissions.CLOUD_CLOUD_SERVER_VIEW])],
        component: () => import('pages/Server.vue') 
      },
      { 
        path: '/disks', 
        beforeEnter: [permissionGuard([Permissions.MANAGEMENT_CLOUD_CLOUD_DISK_VIEW, Permissions.CLOUD_CLOUD_DISK_VIEW])],
        component: () => import('pages/Disks.vue'),
        meta: { title: 'tabs.disks' }
      },
      {
        path: '/disks/create',
        beforeEnter: [permissionGuard([Permissions.MANAGEMENT_CLOUD_CLOUD_DISK_CREATE, Permissions.CLOUD_CLOUD_DISK_CREATE])],
        component: () => import('pages/DiskCreate.vue'),
        meta: { title: 'tabs.create_disk' }
      },
      { 
        path: '/ssh-keys', 
        beforeEnter: [permissionGuard([Permissions.MANAGEMENT_CLOUD_SSH_KEY_VIEW, Permissions.CLOUD_SSH_KEY_VIEW])],
        component: () => import('pages/SSHKeys.vue'),
        meta: { title: 'tabs.ssh_keys' }
      },
      {
        path: '/private-networks',
        beforeEnter: [permissionGuard([Permissions.MANAGEMENT_CLOUD_PRIVATE_NETWORK_VIEW, Permissions.CLOUD_PRIVATE_NETWORK_VIEW])],
        component: () => import('pages/PrivateNetworks.vue'),
        meta: { title: 'tabs.private_networks' }
      },
      {
        path: '/private-networks/create',
        beforeEnter: [permissionGuard([Permissions.MANAGEMENT_CLOUD_PRIVATE_NETWORK_CREATE, Permissions.CLOUD_PRIVATE_NETWORK_CREATE])],
        component: () => import('pages/PrivateNetworksCreate.vue'),
        meta: { title: 'tabs.create_private_network' }
      },
      { 
        path: '/tasks', 
        beforeEnter: [permissionGuard([Permissions.MANAGEMENT_CUSTOMER_TASKS_VIEW, Permissions.CUSTOMER_TASKS_VIEW])],
        component: () => import('pages/Tasks.vue'),
        meta: { title: 'tabs.tasks' }
      },
      {
        path: '/ip-addresses',
        beforeEnter: [permissionGuard([Permissions.MANAGEMENT_CLOUD_IP_ADDRESS_VIEW, Permissions.CLOUD_IP_ADDRESS_VIEW])],
        component: () => import('pages/IpAddresses.vue'),
        meta: { title: 'tabs.ip_addresses' }
      },
      {
        path: '/ip-addresses/create',
        beforeEnter: [permissionGuard([Permissions.MANAGEMENT_CLOUD_IP_ADDRESS_MANAGE, Permissions.CLOUD_IP_ADDRESS_MANAGE])],
        component: () => import('pages/IpAddressCreate.vue'),
        meta: { title: 'tabs.add_ip' }
      },
      {
        path: '/management/customers',
        beforeEnter: [permissionGuard([Permissions.MANAGEMENT_CLOUD_IP_ADDRESS_MANAGE, Permissions.CLOUD_IP_ADDRESS_MANAGE])],
        component: () => import('pages/management/Customers.vue'),
        meta: { title: 'tabs.customers' }
      },
      {
        path: '/management/customer/:id',
        beforeEnter: [permissionGuard([Permissions.MANAGEMENT_CUSTOMER_VIEW])],
        component: () => import('pages/management/Customer.vue')
      },
      {
        path: '/management/users',
        beforeEnter: [permissionGuard([Permissions.MANAGEMENT_CUSTOMER_VIEW])],
        component: () => import('pages/management/Users.vue'),
        meta: { title: 'tabs.users' }
      },
      {
        path: '/management/subnets',
        beforeEnter: [permissionGuard([Permissions.MANAGEMENT_CLOUD_IP_BLOCK_VIEW])],
        component: () => import('pages/management/Subnets.vue'),
        meta: { title: 'tabs.subnets' }
      },
      {
        path: '/management/subnets/create',
        beforeEnter: [permissionGuard([Permissions.MANAGEMENT_CLOUD_IP_BLOCK_CREATE])],
        component: () => import('pages/management/SubnetsCreate.vue'),
        meta: { title: 'tabs.create_subnet' }
      },
      {
        path: '/management/datacenters',
        beforeEnter: [permissionGuard([Permissions.MANAGEMENT_CLOUD_DATACENTER_VIEW])],
        component: () => import('pages/management/Datacenters.vue'),
        meta: { title: 'tabs.datacenters' }
      },
      {
        path: '/management/datacenters/create',
        beforeEnter: [permissionGuard([Permissions.MANAGEMENT_CLOUD_DATACENTER_CREATE])],
        component: () => import('pages/management/DatacenterCreate.vue'),
        meta: { title: 'tabs.create_datacenter' }
      },
      {
        path: '/vmware/vcenters',
        beforeEnter: [permissionGuard([Permissions.MANAGEMENT_VMWARE_VCENTER_VIEW])],
        component: () => import('pages/vmware/vCenters.vue'),
        meta: { title: 'tabs.vcenters' }
      },
      {
        path: '/vmware/vcenters/create',
        beforeEnter: [permissionGuard([Permissions.MANAGEMENT_VMWARE_VCENTER_CREATE])],
        component: () => import('pages/vmware/vCenterCreate.vue'),
        meta: { title: 'tabs.create_vcenter' }
      },
      {
        path: '/vmware/resource-pools',
        beforeEnter: [permissionGuard([Permissions.MANAGEMENT_VMWARE_RESOURCE_POOLS_VIEW])],
        component: () => import('pages/vmware/ResourcePools.vue'),
        meta: { title: 'tabs.resource_pools' }
      },
      {
        path: '/vmware/hosts',
        beforeEnter: [permissionGuard([Permissions.MANAGEMENT_VMWARE_HOSTS_VIEW])],
        component: () => import('pages/vmware/Hosts.vue'),
        meta: { title: 'tabs.hosts' }
      },
      {
        path: '/vmware/datastores',
        beforeEnter: [permissionGuard([Permissions.MANAGEMENT_VMWARE_DATASTORES_VIEW])],
        component: () => import('pages/vmware/Datastores.vue'),
        meta: { title: 'tabs.datastores' }
      },
      {
        path: '/vmware/backup-servers',
        beforeEnter: [permissionGuard([Permissions.MANAGEMENT_CLOUD_BACKUP_SERVERS_VIEW])],
        component: () => import('pages/management/BackupServers.vue'),
        meta: { title: 'tabs.backup_servers' }
      },
      {
        path: '/vmware/disk-image-manager',
        beforeEnter: [permissionGuard([Permissions.MANAGEMENT_CLOUD_CLOUD_DISK_IMAGES_VIEW])],
        component: () => import('pages/management/DiskImageManager.vue'),
        meta: { title: 'tabs.disk_image_manager' }
      },
      {
        path: '/management/feedback-issues',
        beforeEnter: [permissionGuard([Permissions.MANAGEMENT_HELP_REPORTS_VIEW])],
        component: () => import('pages/FeedbackIssues.vue'),
        meta: { title: 'tabs.feedback_issues' }
      },
      {
        path: '/routers',
        beforeEnter: [permissionGuard([Permissions.MANAGEMENT_CLOUD_ROUTER_VIEW, Permissions.CLOUD_ROUTER_VIEW])],
        component: () => import('pages/Routers.vue'),
        meta: { title: 'tabs.routers' }
      },
      {
        path: '/router/create',
        beforeEnter: [permissionGuard([Permissions.MANAGEMENT_CLOUD_ROUTER_VIEW, Permissions.CLOUD_ROUTER_VIEW])],
        component: () => import('pages/RouterCreate.vue'),
        meta: { title: 'tabs.create_router' }
      },
      {
        path: '/routers/:id',
        beforeEnter: [permissionGuard([Permissions.MANAGEMENT_CLOUD_ROUTER_VIEW, Permissions.CLOUD_ROUTER_VIEW])],
        component: () => import('pages/Router.vue')
      },
      {
        path: '/changelog',
        component: () => import('pages/Changelog.vue'),
        meta: { title: 'tabs.changelog' }
      },
      {
        path: '/vmware/imports',
        beforeEnter: [permissionGuard([Permissions.MANAGEMENT_VMWARE_IMPORT_REPORT])],
        component: () => import('pages/vmware/Imports.vue'),
        meta: { title: 'tabs.imports' }
      },
      {
        path: '/billing/products',
        beforeEnter: [permissionGuard([Permissions.MANAGEMENT_PRODUCTS_VIEW])],
        component: () => import('pages/billing/Products.vue'),
        meta: { title: 'tabs.products' }
      },
      {
        path: '/billing/product-categories',
        beforeEnter: [permissionGuard([Permissions.MANAGEMENT_PRODUCT_CATEGORIES_VIEW])],
        component: () => import('pages/billing/ProductCategories.vue'),
        meta: { title: 'tabs.product_categories' }
      },
      {
        path: '/billing/service-overrides',
        component: () => import('pages/billing/ServiceOverrides.vue'),
        meta: { title: 'tabs.service_overrides' }
      },
      {
        path: '/user-profile/:id',
        beforeEnter: [userGuard],
        component: () => import('@/pages/UserProfile.vue')
      },
      {
        path: '/management/registrations',
        beforeEnter: [permissionGuard([Permissions.MANAGEMENT_CUSTOMER_LEADS_VIEW])],
        component: () => import('pages/management/Registrations.vue'),
        meta: { title: 'tabs.registrations' }
      },
      {
        path: '/vmware/backups',
        beforeEnter: [permissionGuard([Permissions.MANAGEMENT_CLOUD_CLOUD_SERVER_BACKUPS])],
        component: () => import('pages/vmware/Backups.vue'),
        meta: { title: 'tabs.backups' }
      },
      {
        path: '/storage-accounts',
        beforeEnter: [permissionGuard([Permissions.MANAGEMENT_CLOUD_STORAGE_VIEW, Permissions.CLOUD_STORAGE_VIEW])],
        component: () => import('pages/StorageAccounts.vue'),
        meta: { title: 'tabs.storage_accounts' }
      },
      {
        path: '/storage-accounts/create',
        beforeEnter: [permissionGuard([Permissions.MANAGEMENT_CLOUD_STORAGE_CREATE, Permissions.CLOUD_STORAGE_CREATE])],
        component: () => import('pages/StorageAccountsCreate.vue'),
        meta: { title: 'tabs.create_storage_account' }
      },
      {
        path: '/management/resource-groups',
        beforeEnter: [permissionGuard([Permissions.MANAGEMENT_RESOURCE_GROUPS_VIEW])],
        component: () => import('pages/management/ResourcesGroups.vue'),
        meta: { title: 'tabs.resources_groups' }
      },
      {
        path: '/management/tasks',
        beforeEnter: [permissionGuard([Permissions.MANAGEMENT_CUSTOMER_TASKS_VIEW])],
        component: () => import('pages/management/Tasks.vue'),
        meta: { title: 'tabs.tasks' }
      },
      {
        path: '/vmware/cpu-tiers',
        beforeEnter: [permissionGuard([Permissions.MANAGEMENT_CLOUD_CPU_TIERS_VIEW])],
        component: () => import('pages/vmware/CpuTiers.vue'),
        meta: { title: 'tabs.cpu_tiers' }
      },
      {
        path: '/vmware/disk-types',
        beforeEnter: [permissionGuard([Permissions.MANAGEMENT_CLOUD_CLOUD_DISK_TYPES_VIEW])],
        component: () => import('pages/vmware/DiskTypes.vue'),
        meta: { title: 'tabs.disk_types' }
      },
      {
        path: '/vpn-concentrators',
        beforeEnter: [permissionGuard([Permissions.MANAGEMENT_CLOUD_VPN_VIEW, Permissions.CLOUD_VPN_VIEW])],
        component: () => import('pages/VpnConcentrators.vue'),
        meta: { title: 'tabs.vpn_concentrators' }
      },
      {
        path: '/vpn-concentrators/create',
        beforeEnter: [permissionGuard([Permissions.MANAGEMENT_CLOUD_VPN_CREATE, Permissions.CLOUD_VPN_CREATE])],
        component: () => import('pages/VpnConcentratorsCreate.vue'),
        meta: { title: 'tabs.create_vpn_concentrator' }
      },
      {
        path: '/vpn-concentrators/:id',
        beforeEnter: [permissionGuard([Permissions.MANAGEMENT_CLOUD_VPN_VIEW, Permissions.CLOUD_VPN_VIEW])],
        component: () => import('pages/VpnConcentrator.vue')
      },
      {
        path: '/management/ip-addresses',
        beforeEnter: [permissionGuard([Permissions.MANAGEMENT_CLOUD_IP_ADDRESS_VIEW])],
        component: () => import('pages/management/IpAddresses.vue'),
        meta: { title: 'tabs.ip_addresses' }
      },
      {
        path: '/billing',
        // beforeEnter: [config.value.BILLING_FEATURES_SHOW],
        component: () => import('pages/Billing.vue'),
        meta: { title: 'tabs.billing' }
      },
      {
        path: '/management/resource-management',
        beforeEnter: [permissionGuard([Permissions.MANAGEMENT_RESOURCES_VIEW])],
        component: () => import('pages/management/ResourcesManagement.vue'),
        meta: { title: 'tabs.resources_management' }
      },
      {
        path: '/management/licenses',
        beforeEnter: [permissionGuard([Permissions.MANAGEMENT_CLOUD_LICENSES_VIEW])],
        component: () => import('pages/management/Licenses.vue'),
        meta: { title: 'tabs.licenses' }
      },
      {
        path: '/management/addon-services',
        beforeEnter: [permissionGuard([Permissions.MANAGEMENT_CLOUD_ADDON_SERVICES_VIEW])],
        component: () => import('pages/management/AddonServices.vue'),
        meta: { title: 'tabs.addon_services' }
      },
      {
        path: '/management/help-topics',
        beforeEnter: [permissionGuard([Permissions.MANAGEMENT_HELP_TOPICS_VIEW])],
        component: () => import('@/pages/management/HelpTopics.vue'),
        meta: { title: 'tabs.help_topics' }
      },
      {
        path: '/dedicated-servers',
        component: () => import('pages/DedicactedServers.vue')
      },
      {
        path: '/dedicated-servers/order',
        beforeEnter: [permissionGuard([Permissions.MANAGEMENT_CLOUD_LICENSES_VIEW])],
        component: () => import('pages/OrderDedicatedServer.vue')
      },
      {
        path: '/dedicated-servers/:id',
        beforeEnter: [permissionGuard([Permissions.MANAGEMENT_CLOUD_LICENSES_VIEW])],
        component: () => import('pages/DedicatedServer.vue')
      },
      {
        path: '/management/ip-bandwidth',
        beforeEnter: [permissionGuard([Permissions.MANAGEMENT_CLOUD_IP_ADDRESS_VIEW_BANDWIDTH])],
        component: () => import('pages/management/BandwidthUsage.vue'),
        meta: { title: 'tabs.ip_bandwidth' }
      },
      {
        path: '/no-customer',
        component: () => import('pages/NoCustomer.vue')
      },
      {
        path: '/management/settings',
        beforeEnter: [permissionGuard([Permissions.MANAGEMENT_CUSTOMER_FEATURE_FLAGS, Permissions.MANAGEMENT_USER_NOTIFICATIONS_VIEW])],
        component: () => import('pages/management/Settings.vue'),
        meta: { title: 'tabs.settings' }
      },
      {
        path: '/dns',
        beforeEnter: [permissionGuard([Permissions.MANAGEMENT_CLOUD_DNS_ZONE_VIEW, Permissions.CLOUD_DNS_ZONE_VIEW])],
        component: () => import('pages/DnsZones.vue'),
        meta: { title: 'tabs.dns_zones' }
      },
      {
        path: '/dns/:id',
        beforeEnter: [permissionGuard([Permissions.MANAGEMENT_CLOUD_DNS_ZONE_VIEW, Permissions.CLOUD_DNS_ZONE_VIEW])],
        component: () => import('pages/DnsZone.vue')
      },
      {
        path: '/dns/create',
        beforeEnter: [permissionGuard([Permissions.MANAGEMENT_CLOUD_DNS_ZONE_CREATE, Permissions.CLOUD_DNS_ZONE_CREATE])],
        component: () => import('pages/DnsZoneCreate.vue'),
        meta: { title: 'tabs.create_dns_zone' }
      },
      {
        path: '/management/dns',
        component: () => import('pages/management/DnsClustersPage.vue'),
        meta: { title: 'tabs.dns_clusters' }
      },
    ]
  },
  {
    path: '/',
    component: () => import('@/layouts/AnonymousLayout.vue'),

    children: [
      {
        path: '/login',
        component: () => import('@/pages/user/Login.vue'),
        meta: { title: 'tabs.login' }
      },
      {
        path: '/register',
        component: () => import('@/pages/user/RegisterUser.vue'),
        meta: { title: 'tabs.register' }
      },
      {
        path: '/reset-password',
        component: () => import('@/pages/user/ResetPassword.vue'),
        meta: { title: 'tabs.reset_password' }
      },
      {
        path: '/forgot-password',
        component: () => import('@/pages/user/ForgotPassword.vue'),
        meta: { title: 'tabs.forgot_password' }
      },
      {
        path: '/demo-application',
        component: () => import('@/pages/itProvDev/SubmitInterestForm.vue'),
        meta: { title: 'tabs.demo_application' }
      },
      {
        path: '/mobile-verification-send',
        component: () => import('@/pages/user/MobileVerificationSend.vue'),
        meta: { title: 'tabs.mobile_verification_send' }
      },
      {
        path: '/mobile-verification-validation',
        component: () => import('@/pages/user/MobileVerificationValidate.vue'),
        meta: { title: 'tabs.mobile_verification_validation' }
      },
      {
        path: '/two-factor-authentication',
        component: () => import('@/pages/user/TwoFactorAuthentication.vue'),
        meta: { title: 'tabs.two_factor_authentication' }
      },
      {
        path: '/user/email/validate',
        component: () => import('@/pages/user/EmailValidation.vue'),
        meta: { title: 'tabs.email_validation' }
      },
      {
        path: '/two-factor-enable',
        name: 'two-factor',
        component: () => import('@/pages/user/TwoFactorEnable.vue'),
        meta: { title: 'tabs.two_factor_enable' }
      }
    ]
  },

  // Always leave this as last one,
  // but you can also remove it
  {
    path: '/Error',
    component: () => import('@/pages/Error500Page.vue')
  },
  {
    path: '/no-access',
    component: () => import('@/pages/NoAccess.vue')
  },
  {
    path: '/:catchAll(.*)*',
    component: () => import('@/pages/Error404Page.vue')
  }
];

export default routes;
