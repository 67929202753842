import {
  DiskStatusEnum,
  CloudServerStatusEnum,
  TasksStatusEnum,
  CloudServerTaskType,
  CloudDiskTaskType,
  IpAddressTaskType,
  NetworkTaskType,
  FirewallTaskType,
  RouterTaskType,
  StorageAccountTaskType,
  ErrorMessages,
  VpnConcentratorTaskType,
  DedicatedServerTaskType,
  DnsTaskType
} from '@/commons/Enums';

export default {
  status: {
    // Task Status
    [TasksStatusEnum.PENDING]: 'Pending',
    [TasksStatusEnum.RUNNING]: 'Running',
    [TasksStatusEnum.COMPLETE]: 'Complete',
    [TasksStatusEnum.FAILED]: 'Failed',
    [TasksStatusEnum.CANCELLED]: 'Cancelled',
    [TasksStatusEnum.PROCESSING]: 'Processing',
    [TasksStatusEnum.DISABLING]: 'Disabling',
    // Server task Status
    [CloudServerStatusEnum.PENDING]: 'Pending',
    [CloudServerStatusEnum.DONE]: 'Done',
    [CloudServerStatusEnum.FAILED]: 'Failed',
    [CloudServerStatusEnum.PROCESSED]: 'Processed',
    [CloudServerStatusEnum.PROVISIONING]: 'Provisioning',
    [CloudServerStatusEnum.PROCESSING]: 'Processing',
    // Disk task Status
    [DiskStatusEnum.PENDING]: 'Pending',
    [DiskStatusEnum.DETACHED]: 'Detached',
    [DiskStatusEnum.ATTACHED]: 'Attached',
    [DiskStatusEnum.PROVISIONING]: 'Provisioning',
    [DiskStatusEnum.FAILED]: 'Failed',
    [DiskStatusEnum.PROCESSING]: 'Processing',
    // Karfwta
    SHOULD_NO_EXECUTE: 'Task Cancelled',
    DELETED: 'Deleted',
    CREATED: 'Created',
    PROCESSING: 'Processing',
    ENABLING: 'Enabling',
    PREPARING: 'Preparing',
    CREATING: 'Creating',
    DELETING: 'Deleting',
    UPDATING: 'Updating',
    RESTORING: 'Restoring'
  },
  type: {
    // Server Task Types

    [CloudServerTaskType.CLOUD_SERVER_CREATE]: 'Create Server',
    [CloudServerTaskType.CLOUD_SERVER_DELETE]: 'Delete Server',
    [CloudServerTaskType.CLOUD_SERVER_POWER]: 'Power Server',
    [CloudServerTaskType.CLOUD_SERVER_REINSTALL]: 'Reinstall Server',
    [CloudServerTaskType.CLOUD_SERVER_UNMOUNT]: 'Unmount Image',
    [CloudServerTaskType.CLOUD_SERVER_MOUNT]: 'Mount Image',
    [CloudServerTaskType.CLOUD_SERVER_RESCALE]: 'Rescale Server',
    [CloudServerTaskType.CLOUD_SERVER_ENABLE_VNC]: 'Enable VNC to Server',
    [CloudServerTaskType.CLOUD_SERVER_SNAPSHOT_CREATE]: 'Create Snapshot',
    [CloudServerTaskType.CLOUD_SERVER_SNAPSHOT_DELETE]: 'Delete Snapshot',
    [CloudServerTaskType.CLOUD_SERVER_SNAPSHOT_RESTORE]: 'Restore Snapshot',
    [CloudServerTaskType.CLOUD_SERVER_NETWORK_CARD_CHANGE]:
      'Change Network Card',
    [CloudServerTaskType.CLOUD_SERVER_NETWORK_CARD_CREATE]:
      'Create Network Card',
    [CloudServerTaskType.CLOUD_SERVER_NETWORK_CARD_DELETE]:
      'Delete Network Card',
    [CloudServerTaskType.CLOUD_SERVER_NETWORK_CARD_CONNECT]:
      'Connect Network Card',
    [CloudServerTaskType.CLOUD_SERVER_NETWORK_CARD_DISCONNECT]:
      'Disconnect Network Card',
    [CloudServerTaskType.CLOUD_SERVER_BACKUP_DISABLE]: 'Disable Backups',
    [CloudServerTaskType.CLOUD_SERVER_BACKUP_ENABLE]: 'Enable Backups',
    [CloudServerTaskType.CLOUD_SERVER_BACKUP_RESTORE]: 'Restore Backup',
    [CloudServerTaskType.CLOUD_SERVER_BACKUP_UPDATE]: 'Update Backup',
    [CloudServerTaskType.CLOUD_SERVER_BACKUP_RESTORE_DISKS]:
      'Restore Backup Disks',
    [CloudServerTaskType.CLOUD_SERVER_FIREWALL_RULES_APPLY]:
      'Apply Firewall Rules',
    [CloudServerTaskType.CLOUD_SERVER_FIREWALL_RULES_UNDO]:
      'Undo Firewall Rules',
    [CloudServerTaskType.CLOUD_SERVER_IMPORT]: 'Import Cloud Server',
    [CloudServerTaskType.CLOUD_SERVER_ENABLE_RESCUE_MODE]: 'Enable Rescue Mode',
    [CloudServerTaskType.CLOUD_SERVER_DISABLE_RESCUE_MODE]:
      'Disable Rescue Mode',

    [CloudServerTaskType.CLOUD_SERVER_DISK_MIGRATE]:
      'Cloud Server Disk Migrate',
    [CloudServerTaskType.CLOUD_SERVER_RESOURCE_POOL_MOVE]:
      'Cloud Server Resource Pool Move',
    [CloudServerTaskType.CLOUD_SERVER_CROSS_MIGRATE]:
      'Cloud Server Cross Migrate',
    [CloudServerTaskType.CLOUD_SERVER_CLONE]: 'Clone Cloud Server',
    [CloudServerTaskType.CLOUD_SERVER_ADVANCED_CONFIGURATION]:
      'Cloud Server Advanced Configuration',

    // Disk Task Types
    [CloudDiskTaskType.CLOUD_SERVER_DISK_CREATE]: 'Create Cloud Disk',
    [CloudDiskTaskType.CLOUD_SERVER_DISK_CHANGE_TYPE]: 'Change Disk Type',
    [CloudDiskTaskType.CLOUD_SERVER_DISK_DELETE]: 'Delete Cloud Disk',
    [CloudDiskTaskType.CLOUD_SERVER_DISK_EXTEND]: 'Extend Cloud Disk',
    [CloudDiskTaskType.CLOUD_SERVER_DISK_MOVE]: 'Move Cloud Disk',

    // IP Adresses
    [IpAddressTaskType.IP_ADDRESS_ASSIGN]: 'Assign IP Address',
    [IpAddressTaskType.IP_ADDRESS_MOVE]: 'Move IP Address',
    [IpAddressTaskType.IP_ADDRESS_RELEASE]: 'Release IP Address',
    [IpAddressTaskType.IP_ADDRESS_SET_REVERSE]: 'Set Reverse IP Address',

    // Network Task Types
    [NetworkTaskType.PRIVATE_NETWORK_DELETE]: 'Delete Private Network',
    [NetworkTaskType.PRIVATE_NETWORK_CREATE]: 'Create Private Network',
    // Routers

    [RouterTaskType.ROUTER_CREATE]: 'Create Router',
    [RouterTaskType.ROUTER_DELETE]: 'Delete Router',
    [RouterTaskType.ROUTER_FIREWALL_APPLY]: 'Apply Firewall Rules',
    [RouterTaskType.ROUTER_FIREWALL_UNDO]: 'Undo Firewall Rules',
    [RouterTaskType.ROUTER_IPSEC_APPLY]: 'Apply IPSEC to Router',
    [RouterTaskType.ROUTER_IPSEC_UNDO]: 'Undo IPSEC to Router',
    [RouterTaskType.ROUTER_LOAD_BALANCER_CREATE]: 'Create Load Balancer',
    [RouterTaskType.ROUTER_LOAD_BALANCER_DELETE]: 'Delete Load Balancer',
    [RouterTaskType.ROUTER_LOAD_BALANCER_EDIT]: 'Edit Load Balancer',
    [RouterTaskType.ROUTER_LOAD_BALANCER_EDIT_MEMBERS]:
      'Edit Load Balancer Members',
    [RouterTaskType.ROUTER_ADD_NETWORK_CARD]: 'Add Network Card',
    [RouterTaskType.ROUTER_NETWORK_CARD_DELETE]: 'Delete Network Card',
    [RouterTaskType.ROUTER_NETWORK_CARD_UPDATE]: 'Update Network Card',
    [RouterTaskType.ROUTER_RESCALE]: 'Rescale Router',
    [RouterTaskType.ROUTER_STATIC_ROUTE_APPLY]: 'Apply Static Route',
    [RouterTaskType.ROUTER_STATIC_ROUTE_UNDO]: 'Undo Static Route',
    [RouterTaskType.ROUTER_ADD_CERTIFICATE]: 'Add Certificate',
    [RouterTaskType.ROUTER_DELETE_CERTIFICATE]: 'Delete Certificate',
    [RouterTaskType.ROUTER_NAT_APPLY]: 'Apply NAT Rules',
    [RouterTaskType.ROUTER_NAT_UNDO]: 'Undo NAT Rules',
    [RouterTaskType.ROUTER_REVERSE_PATH_UPDATE]: 'Router reverse path update',
    [RouterTaskType.ROUTER_GATEWAY_APPLY]: 'Gateway Configuration',

    // Storage Accounts
    [StorageAccountTaskType.FTP_ACCOUNT_CREATE]: 'Create Storage Account',
    [StorageAccountTaskType.FTP_ACCOUNT_EDIT_PASSWORD]:
      'Edit Storage Account Password',
    [StorageAccountTaskType.FTP_ACCOUNT_DELETE]: 'Delete Storage Account',
    [StorageAccountTaskType.FTP_ACCOUNT_DISABLE]: 'Disable Storage Account',
    [StorageAccountTaskType.FTP_ACCOUNT_RESIZE]: 'Resize Storage Account',
    // Firewalls
    [FirewallTaskType.UNDO_CLOUDSERVER_FIREWALL_RULES]: 'Undo Firewall Rules',
    [FirewallTaskType.APPLY_CLOUDSERVER_FIREWALL_RULES]: 'Apply Firewall Rules',

    // VpnConcentrators
    [VpnConcentratorTaskType.VPN_CONCENTRATOR_CREATE]:
      'Create VPN Concentrator',
    [VpnConcentratorTaskType.VPN_CONCENTRATOR_DELETE]:
      'Delete VPN Concentrator',
    [VpnConcentratorTaskType.VPN_CONCENTRATOR_POOL_CREATE]:
      'Create VPN Concentrator IP Pool',
    [VpnConcentratorTaskType.VPN_CONCENTRATOR_POOL_EDIT]:
      'Edit VPN Concentrator IP Pool',
    [VpnConcentratorTaskType.VPN_CONCENTRATOR_POOL_DELETE]:
      'Delete VPN Concentrator IP Pool',
    [VpnConcentratorTaskType.VPN_CONCENTRATOR_PROFILE_CREATE]:
      'Create VPN Concentrator Profile',
    [VpnConcentratorTaskType.VPN_CONCENTRATOR_PROFILE_DELETE]:
      'Delete VPN Concentrator Profile',
    [VpnConcentratorTaskType.VPN_CONCENTRATOR_PROFILE_EDIT]:
      'Edit VPN Concentrator Profile',
    [VpnConcentratorTaskType.VPN_CONCENTRATOR_USER_CREATE]:
      'Create VPN Concentrator User',
    [VpnConcentratorTaskType.VPN_CONCENTRATOR_USER_DELETE]:
      'Delete VPN Concentrator User',
    [VpnConcentratorTaskType.VPN_CONCENTRATOR_USER_EDIT]:
      'Edit VPN Concentrator User',
    [VpnConcentratorTaskType.VPN_CONCENTRATOR_NETWORK_CARD_CREATE]:
      'Create VPN Concentrator Network Card',
    [VpnConcentratorTaskType.VPN_CONCENTRATOR_NETWORK_CARD_DELETE]:
      'Delete VPN Concentrator Network Card',
    [VpnConcentratorTaskType.VPN_CONCENTRATOR_NETWORK_CARD_EDIT]:
      'Edit VPN Concentrator Network Card',
    [VpnConcentratorTaskType.VPN_CONCENTRATOR_STATIC_ROUTE_CREATE]:
      'Create VPN Concentrator Static Route',
    [VpnConcentratorTaskType.VPN_CONCENTRATOR_STATIC_ROUTE_UPDATE]:
      'Edit VPN Concentrator Static Route',
    [VpnConcentratorTaskType.VPN_CONCENTRATOR_STATIC_ROUTE_DELETE]:
      'Delete VPN Concentrator Static Route',
    [VpnConcentratorTaskType.VPN_CONCENTRATOR_REGENERATE_CONFIG]:
      'Regenerate User Config',

    // DNS
    [DnsTaskType.DNS_ZONE_CREATE]: 'Create DNS Zone',
    [DnsTaskType.DNS_ZONE_DELETE]: 'Delete DNS Zone',
    [DnsTaskType.DNS_ZONE_APPLY]: 'Apply DNS Zone Records',
    [DnsTaskType.DNS_ZONE_UNDO]: 'Undo DNS Zone Records',

    CUSTOMER_RESOURCES_SYNC: 'Customer Resources Sync',
    CUSTOMER_ORDERS_SYNC: 'Customer Orders Sync',
    VMWARE_IMPORT_REPORT_GENERATE: 'Vmware Import Report Generate',

    ROUTER_LOAD_BALANCER_REDIRECT_CREATE:
      'Router Load Balancer Redirect Create',
    ROUTER_LOAD_BALANCER_REDIRECT_EDIT: 'Router Load Balancer Redirect Edit',

    [DedicatedServerTaskType.DEDICATED_SERVER_POWER]: 'Power Server',

    CLOUD_SERVER_MIGRATE: 'Migrate Cloud Server'

    // Karfwta
  },
  error: {
    [ErrorMessages.NO_LAST_NETWORK_CARD_DELETE]:
      'Server must have at least one network card',
    [ErrorMessages.PASSWORD_INSECURE]: 'Password is not secure',
    [ErrorMessages.INVALID_NAME]: 'Invalid Name',
    // generic validation errors

    VALIDATION_REQUIRED: 'The ${field} field is required.',
    VALIDATION_TYPE_STRING: 'The ${field} field should be a string.',
    VALIDATION_TYPE_FILE: 'The ${field} field is not a valid file.',
    VALIDATION_MAX: '${field} field is over the allowed limit.',
    VALIDATION_TYPE_ARRAY: 'The ${field} field should be an array.',
    VALIDATION_TYPE_BOOLEAN: 'Τhe ${field} field must be a boolean.',
    VALIDATION_NOT_EXISTS: '${field} does not exist.',
    VALIDATION_REGEX_MISMATCH: '${field} validation failed.',
    VALIDATION_CONFIRM_MISMATCH: '${field} confirmation not matching.',
    VALIDATION_MIN: '${field} field is under the allowed limit.',
    VALIDATION_CONDITIONAL_REQUIRED: '${field} field is currently required.',
    VALIDATION_UNKNOWN_VALUE: '${field} got unexpected value.',
    VALIDATION_TYPE_INTEGER: 'The ${field} field must be a Integer.',
    VALIDATION_DIGIT_RANGE: '${field} field is out of allowed range.',
    VALIDATION_STARTS_WITH: '${field} invalid format.',
    VALIDATION_PRESENT: 'The field ${field} must me present.',
    VALIDATION_TYPE_EMAIL: 'field ${field} must be an email.',
    VALIDATION_TYPE_DATE: 'The field ${field} must be a valid date.',
    VALIDATION_DATE_AFTER: 'The field ${field} is too soon.',
    VALIDATION_TYPE_NUMERIC: 'The ${field} field must be a Number.',

    // business logic errors

    UNKNOWN_INSTANCE_TYPE: 'Unknown instance type.',
    NOT_CONFIGURED: 'Feature not configured.',
    CANNOT_ALLOCATE_IP: 'Failed to allocate ip address.',
    NO_SUBNET_AVAILABLE: 'Could not find available network.',
    NO_PUBLIC_IP_AVAILABLE: 'Could not find available ip address.',
    UNKNOWN_SPOOFGUARD_POLICY: 'SpoofPolicy Missing.',
    NETWORKS_NOT_ARRAY: 'Network Range Validation issue.',
    PRICING_OVERRIDE_ERROR: 'Product pricing configuration error.',
    UNKNOWN_PRODUCT_PRICE_TYPE: 'Unknown product price type.',
    RESOURCE_VALUE_ERROR: 'Error processing resource value.',
    SMS_KEY_NOT_CONFIGURED: 'SMS Sender Not configured.',
    NO_AVAILABLE_DATASTORE: 'Cannot allocate capacity.',
    NO_AVAILABLE_VNC_PORT: 'Cannot configure virtual console.',
    FETCH_SPOOFGUARD_POLICIES_ERROR: 'SpoofPolicy fetch error.',
    BACKUPS_ALREADY_ENABLED: 'Backups are already enabled.',
    BACKUPS_UNAVAILABLE_ON_LOCATION:
      'Backups are not currently available at this location.',
    BACKUPS_ALREADY_DISABLED: 'Backups are already disabled.',
    BACKUPS_DISABLED: 'Backups are disabled for this Cloud Server.',
    WRONG_RESTORE_POINT: 'Unknown backup restore point.',
    BACKUP_SERVER_UNAVAILABLE: 'Backup Server is unavailable.',
    BACKUP_JOB_RUNNING: 'A backup job is running at the moment.',
    DISK_SIZE_IS_LESS_THAN_THE_MINIMUM:
      'Disk size is less than operating systems minimum requirement.',
    NAME_ALREADY_IN_USE: 'This name is already in use.',
    NETWORK_NOT_READY: 'The private network is not available at the moment.',
    INVALID_IP_ADDRESS: 'Invalid ip address format.',
    GATEWAY_DOES_NOT_BELONG_TO_NETWORK: 'Gateway should exist in subnet.',
    NO_PUBLIC_NETWORK: 'Network not found.',
    NO_ACCESS_SSH_KEY: 'Ssh key not found.',
    SERVER_EXISTS_IN_LOAD_BALANCER: 'Cloud Server exists in load balancer.',
    SERVER_MUST_POWERED_OFF: 'Cloud Server must be powered off.',
    UNKNOWN_STATE: 'Unknown Cloud Server state.',
    CLOUD_SERVER_NOT_POWERED_OFF: 'Cloud Server must be powered off.',
    OPERATING_SYSTEM_MISMATCH: 'You can not use this operating system.',
    VNC_ALREADY_SET: 'Console has been already configured.',
    NO_DELETE_PRIMARY: 'Primary disk cannot be deleted.',
    NO_ACCESS_TARGET_CLOUD_SERVER:
      "You don't have access to the target Cloud Server.",
    DIFFERENT_SERVER_LOCATION:
      'You cannot move disks across different locations yet.',
    DIFFERENT_OPERATING_SYSTEM:
      'You cannot move disks across operating systems.',
    INVALID_LOCATION: 'Invalid Location',
    UNKNOWN_LOCATION: 'Unknown Location',
    NO_DISK_IMAGE_MOUNTED: 'There is no disk image mounted',
    SINGLE_PUBLIC_ALLOWED: 'You can only have one public network card.',
    PUBLIC_CARD_NO_PUBLIC_NETWORK:
      'Public network card should connect to public network.',
    NETWORK_ALREADY_CONNECTED:
      'Your server is already connected to this network.',
    NO_LAST_NETWORK_CARD_DELETE:
      'You cannot remove all your server network cards.',
    NO_PUBLIC_NETWORK_CARD:
      'You should have a public network card to allocate ip addresses.',
    IPBLOCK_IN_USE: 'IpAddress block is currently in use.',
    ATTACHABLE_BUSY: 'Instance is busy.',
    ROUTER_UPLINK_IS_PRIVATE_NETWORK:
      'Router should have public uplink to allocate ip addresses.',
    IP_AVAILABLE: 'IP Address origin not found.',
    SAME_SOURCE_DESTINATION_INSTANCE: 'Same origin and target instance.',
    ROUTER_HAS_ONLY_ONE_IP_ADDRESS:
      'You cannot remove all IP addresses from router.',
    IP_SEC_USES_THIS_IP_ADDRESS:
      'This IP Address is being used by an IPSec Site.',
    LOAD_BALANCER_USES_THIS_IP_ADDRESS:
      'This IP Address is being used by a Load Balancer.',
    NETWORK_IN_USE: 'Network is in use.',
    RESTRICTED_ACTION: 'You cannot delete a semi-managed private network.',
    INVALID_IP_ADDRESS_IN_PRIVATE_NETWORK: 'IP Address not in network.',
    NETWORK_OVERLAP: 'Network overlapping.',
    GATEWAY_CANNOT_MATCH_WITH_CIDR:
      'Gateway IP Address should not be the same as routers.',
    ADD_CERTIFICATE_ERROR: 'Unknown Error adding your certificate.',
    DELETE_CERTIFICATE_ERROR: 'Unknown Error deleting your certificate.',
    CANNOT_HAVE_MORE_NETWORK_CARDS:
      'You reached the network cards limit for this router.',
    CANNOT_CONNECT_NETWORK_CARD_TO_SAME_NETWORK:
      'Your instance is already connected to this network.',
    CANNOT_CONFIGURE_SAME_SUBNET:
      'Your instance has already configured this subnet.',

    OVERLAPPING_NETWORK_ADDRESS_1: 'Overlapping Check1',
    OVERLAPPING_NETWORK_ADDRESS_2: 'Overlapping Check2',
    SECONDARY_IP_DOES_NOT_BELONG_TO_NETWORK:
      'Secondary IP Address should belong to network.',
    NETWORK_CARD_TYPE_MISSMATCH_WITH_NETWORK_TYPE:
      'Network card type must be same as networks.',
    IP_SEC_USES_UPLINK_IP_ADDRESS:
      'This uplink IP Address is being used by an IPSec Site.',
    LOAD_BALANCER_USES_UPLINK_IP_ADDRESS:
      'This uplink IP Address is being used by a Load Balancer.',
    CANNOT_EDIT_PUBLIC_NETWORK_WITH_ANOTHER_ONE:
      'You cannot change uplink network.',
    SECONDARY_IPS_NOT_ALLOWED:
      'You cannot add secondary ip addresses manually.',
    NETWORK_TYPE_MISSMATCH: 'Issue with network type.',
    NETWORK_ADDRESS_CONFLICT: 'IP Address conflict.',
    OVERLAPPING_NETWORK_ADDRESS: 'Overlapping network addresses.',
    NETWORK_CARD_IN_USE_FOR_LOAD_BALANCER:
      'This network card is being used by a Load Balancer.',
    NETWORK_CARD_IN_USE_FOR_IP_SEC:
      'This network card is being used by an IPSec Site.',
    NETWORK_CARD_IN_USE_FOR_STATIC_ROUTING:
      'This network card is being used by a Static Route.',
    UPLINK_NETWORK_CARD_MUST_BE_PUBLIC:
      'Uplink network card must be public to enable this feature.',
    REMOTE_ENDPOINT_CANNOT_MATCH_LOCAL_ENDPOINT:
      'Remote endpoint must not be same as local.',
    REMOTE_ENDPOINT_ALREADY_EXISTS: 'Remote endpoint already exists.',
    REMOTE_ENDPOINT_IS_USED_IN_ROUTER: 'Remote endpoint is used in router.',
    NOT_VALID_CIDR: 'Invalid CIDR.',
    INVALID_LOCAL_SUBNET: 'Invalid Local Subnet.',
    INVALID_PUBLIC_SUBNET: 'Invalid Public Subnet.',
    LOCAL_SUBNET_MUST_BE_SUBSET_OF_PRIVATE_NETWORK_OR_STATIC_ROUTE:
      'Local subnet must be in network or static route.',
    INVALID_REMOTE_SUBNET: 'Invalid Remote Subnet.',
    REMOTE_SUBNET_ALREADY_EXISTS: 'Remote Subnet already exists.',
    REMOTE_SUBNET_CANNOT_OVERLAP_PRIVATE_NETWORKS:
      'Remote subnet cannot overlap with private networks.',
    LOADBALANCER_INVALID_IP_ADDRESS:
      'Invalid IP Address selected for Load Balancer.',
    LOADBALANCER_DUPLICATE_PORTS_ERROR: 'Duplicate ports used.',
    INVALID_IP_ADDRESS_FOR_SERVICE: 'Invalid IP Address for service.',
    UNABLE_TO_FETCH_DATA: 'Unknown error while fetching data.',
    SOURCE_IP_MUST_BE_SPECIFIED: 'Source IP Address is required.',
    DESTINATION_IP_MUST_BE_SPECIFIED: 'Destination IP Address is required.',
    TRANSLATED_IP_MUST_BE_SPECIFIED: 'Translated IP Address is required.',
    INVALID_SOURCE_IP_ADDRESS_FORMAT: 'Invalid Source IP Address format.',
    INVALID_DESTINATION_IP_ADDRESS_FORMAT:
      'Invalid Destination IP Address format.',
    INVALID_PREFIX: 'Invalid prefix',
    SOURCE_IP_MUST_BE_EQUAL_OR_SUBSET_OF_PRIVATE_NETWORK_OR_STATIC_ROUTE:
      'Source IP Address must be subnet of private network or static route.',
    SOURCE_IP_STATIC_ROUTE_NO_NEXT_HOP_IN_APPLIED_ON:
      'Static route should have next hop on applied network.',
    SOURCE_IP_MUST_BE_EQUAL_OR_SUBSET_OF_PRIVATE_NETWORK:
      'Source IP Address must be equal or subnet of private network or static route.',
    SOURCE_ADDRESS_AND_DESTINATION_ADDRESS_CANNOT_BELONG_TO_THE_SAME_NETWORK:
      'Source and Destination address cannot belong to same network.',
    DESTINATION_IP_NO_STATIC_NEXT_HOP_IN_SOURCE_NETWORK:
      'Destination IP Address should have no static route with next hop in source network.',
    INVALID_TRANSLATED_IP: 'Invalid Translated IP Address format.',
    TRANSLATED_IP_MUST_BE_EQUAL_OR_SUBSET_OF_PRIVATE_NETWORK_OR_STATIC_ROUTE:
      'Translated IP Address should be equal or subnet of private network or static route.',
    TRANSLATED_IP_STATIC_NEXT_HOP_IS_USED_IN_APPLIED_NETWORK:
      'Translated IP Addresses static route next hop exists in applied network.',
    TRANSLATED_IP_MUST_BE_EQUAL_OR_SUBSET_OF_PRIVATE_NETWORK:
      'Translated IP Address should be equal or subnet of private network.',
    NEXT_HOP_IS_NOT_USED_IN_APPLIED_NETWORK:
      'Next hop of static route must exist in applied on network.',
    INVALID_DESTINATION_IP: 'Invalid Destination IP.',
    UNKNOWN_NAT_TYPE: 'Unknown NAT Type.',
    INVALID_SOURCE_PORT_FORMAT: 'Invalid Source Port Format.',
    INVALID_SOURCE_PORT_VALUES: 'Invalid Source port values.',
    INVALID_SOURCE_PORT_VALUE: 'Invalid Source port value.',
    INVALID_DESTINATION_PORT_FORMAT: 'Invalid Destination Port Format.',
    INVALID_DESTINATION_PORT_VALUES: 'Invalid Destination port values.',
    INVALID_DESTINATION_PORT_VALUE: 'Invalid Destination port value.',
    INVALID_TRANSLATED_PORT_FORMAT: 'Invalid Translated Port Format.',
    INVALID_TRANSLATED_PORT_VALUES: 'Invalid Translated port values.',
    INVALID_TRANSLATED_PORT_VALUE: 'Invalid Translated port value.',
    INVALID_NETWORK_ADDRESS: 'Invalid network address.',
    NETWORK_ADDRESS_MATCHES_UPLINK_INTERFACE:
      'Network address is same with uplink address.',
    INVALID_NEXT_HOP_ADDRESS: 'Invalid next hop address.',
    NETWORK_ADDRESS_CONFLICT_WITH_NETWORK_CARD:
      'Network address conflicts with other network.',
    NETWORK_ADDRESS_CANNOT_BE_SUBNET_OF_NETWORK_CARD:
      'Network address must not be subnet of other network.',
    STATIC_ROUTE_IS_IN_USE_FOR_AN_IP_SEC_SITE:
      'Static route is used by an IPSec Site.',
    STORAGE_SERVER_INACTIVE: 'Storage server is not active.',
    NO_DIFFERENCE_IN_ACCOUNT_SIZE: 'Storage Account site is the same.',
    CANNOT_RESIZE_ACCOUNT_DUE_TO_ITS_CURRENT_USAGE:
      'Cannot decrease size beneath current usage.',
    INVALID_FOLDER_FORMAT: 'Invalid folder format.',
    FILE_NO_EXISTS: "This file doesn't exist.",
    FOLDER_ALREADY_EXISTS: 'This folder already exists.',
    FOLDER_NO_EXISTS: "This folder doesn't exist.",
    NOT_READY: 'Instance is not ready.',
    FORBIDDEN_IP_ADDRESS: 'You are connected from a restricted network.',
    TWOFA_ALREADY_ENABLED: 'Two-Factor authentication is already enabled.',
    TWOFA_NOT_ENABLED: 'Two-Factor authentication is not enabled.',
    TWOFA_INVALID: 'Invalid Two-Factor authentication code.',
    TWOFA_NOT_PENDING: 'Two-Factor authentication is not pending validation.',
    PHONE_ALREADY_VERIFIED: 'Mobile phone is already verified.',
    SMS_SEND_ERROR: 'Error sending mobile verification code.',
    NO_SAME_PASSWORD: 'You are already using this password.',
    WRONG_OLD_PASSWORD: 'Error validating your old password.',
    INVALID_NETWORK: 'Invalid Network format.',
    INVALID_NETWORK_PREFIX: 'Invalid Network prefix.',
    EMAIL_ALREADY_VERIFIED: 'Your email has been already verified.',
    INVALID_SMS_TOKEN: 'Invalid Mobile Verification Token.',
    INVALID_SMS_TOKEN_EXPIRED:
      'Mobile Verification Token expired, please request a new one.',
    INVALID_EMAIL_TOKEN: 'Invalid Email Verification Token.',
    INVALID_EMAIL_TOKEN_EXPIRED:
      'Email Verification Token expired, please request a new one.',
    INVALID_FORMAT: 'Invalid data format.',
    LEAD_REGISTRATION_NOT_ENABLED:
      'Application Registrations are not available at this time.',
    PRODUCT_PRICING_EXISTS: 'Product pricing already exists.',
    BUSY: 'You cannot do this while another task is running.',
    BACKUP_RUNNING: 'You cannot do this while instance is backing up.',
    SERVER_READONLY: 'You cannot do this while Cloud Server is read only.',
    SERVER_ACTIVE_SNAPSHOT:
      'You cannot do this while Cloud Server has active snapshot.',
    ANOTHER_DISK_IMAGE_IS_ALREADY_MOUNTED:
      'You cannot enable rescue mode while there is a mounted disk image.',
    CLOUD_SERVER_IS_NOT_IN_RESCUE_MODE: 'Your server is not in rescue mode.',
    POOL_NAME_ALREADY_EXISTS: 'IP Pool name already exists',
    CANNOT_EDIT_IP_ADDRESSES: 'Can not edit IP address',
    OTHER_COMPANY: 'A user with this e-mail already exists.',

    SERVER_IS_IN_RESCUE_MODE: 'Cloud Server is in Rescue Mode',
    SERVER_IS_PROTECTED: 'Cloud Server is Protected',
    ROUTER_IS_PROTECTED: 'Router is Protected',
    CANNOT_DELETE_ADMIN_FIREWALL_RULE: 'Cannot delete Admin Firewall Rule',
    VPN_NOT_DEPLOYED: 'VPN not Deployed',
    INVALID_IP_ADDRESS_RANGE: 'Inavlid IP Address Range',
    LOCAL_ADDRESS_MUST_BE_THE_FIRST_IP_OF_THE_POOL:
      'Local Address must be the first Ip of the pool',
    INVALID_DNS_IP_ADDRESS: 'Invalid DNS IP Address',
    INVALID_ROUTE_IP_ADDRESS: 'Invalid Route IP Address',
    INVALID_ROUTE_NETMASK: 'Invalid Route Netmask',
    DISK_IS_ALREADY_STORED_TO_THIS_DATASTORE:
      'Disk is already stored to this Datastore',
    NO_AVAILABLE_FREE_SPACE_TO_THE_SELECTED_DATASTORE:
      'No available free space to the selected Datastore',
    CLOUD_SERVER_ALREADY_EXISTS_TO_THE_RESOURCE_POOL:
      'Cloud Server already exists to the selected resource pool',
    EXCEEDED_RESOURCE_POOL_MAX_VM_LIMIT:
      'Maximum resource pool VM limit exceeded',
    CLOUD_SERVER_IS_NOT_READY: 'Cloud Server is not ready',
    INVALID_EXPIRATION_DATE: 'Invalid Expiration Date',
    CANNOT_EDIT_THIS_IP_ADDRESS: 'Cannot edit this IP Address',
    CANNOT_CHANGE_IP_ADDRESS_RESERVATION:
      'Cannot change IP Address Reservation',
    CANNOT_EDIT_PUBLIC_NETWORK: 'Cannot edit Public Network',
    NETWORK_IS_NOT_READY: 'Network is not ready',
    RECAPTCHA_API_ERROR: 'Recaptcha Api Error',
    TOKEN_EXPIRED: 'Token expired',
    BILLING_UNAVAILABLE: 'Billing unavailable',
    FIRMWARE_NOT_FOUND: 'Firmware not found',
    FAILED_TO_SHUTDOWN: 'Failed to shutdown',
    NO_AVAILABLE_IP_FOUND: 'No available IP found',
    FAILED_TO_APPROVE_IP: 'Failed to approve IP',
    FAILED_TO_PUBLISH_IP: 'Failed to publish IP',
    UNKNOWN_PYLON_CUSTOMER: 'Uknown Pylon customer',
    ORDER_ALREADY_EXISTS: 'Order already exists',
    CANNOT_UPDATE_UNKNOWN_ORDER: 'Cannot update uknown order',
    INSTANCE_STATUS_IS_DISABLED: 'Instance status is disabled',
    THIS_OPERATION_IS_UNAVAILABLE: 'This operation is unavailable',
    INFRASTRUCTURE_IS_UNAVAILABLE: 'Infrastructure is unavailable',
    ERROR_WITH_SCOPES: 'Error with scopes',
    DATA_NOT_FETCHED: 'Data not fetched',
    CORES_MUST_BE_MULTIPLE_OF_CORES_PER_SOCKET:
      'Cores must be multiple of cores per socker',
    NO_OTHER_PUBLIC_NETWORKS: 'No other public networks',
    CANNOT_CONNECT_UNASSIGNED_CARDS: 'Cannot Connect Unassigned Cards',
    CANNOT_DISCONNECT_UNASSIGNED_CARDS: 'Cannot Disconnect Unassigned Cards',
    IP_ADDRESS_DOES_NOT_BELONG_TO_THIS_NETWORK:
      'IP Adrress does not belong to this Network',
    FILE_NOT_FOUND: 'File not found',
    INSTANCE_MUST_HAVE_AT_LEAST_ONE_PUBLIC_NETWORK_CARD:
      'Instance must have at least one public network card',
    VPN_CONCENTRATOR_CANNOT_RELEASE_PUBLIC_IP_ADDRESS:
      'VPN Concentrator can not release',
    PRIVATE_IP_ADDRESS_CANNOT_BELONG_TO_PUBLIC_NETWORK:
      'Private IP Address can not belong to public network',
    OVERLAPPING_NETWORK_ADDRESS_WITH_IP_SEC:
      'Overlapping Network Address with IP Sec',
    SECONDARY_CANNOT_MATCH_WITH_PRIMARY_IP:
      'Seconday can not match with primary IP',
    NETWORK_CARDS_DO_NOT_BELONG_TO_THIS_ROUTER:
      'Network cards do not belong to this Router',
    STATIC_ROUTE_USES_TUNNEL_IP_ADDRESS: 'Static Route uses tunnel IP Address',
    GLOBAL_PSK_MUST_BE_SET: 'Global Pre Shared Key must be set',
    TUNNEL_CIDR_IS_ALREADY_USED_IN_ROUTER:
      'Tunnel CIDR is already used in Router',
    TUNNEL_CIDR_IS_ALREADY_USED_IN_ROUTER_INTERNAL_CARD:
      'Tunnel CIDR is already used in Router internal card',
    CANNOT_EDIT_SESSION_TYPE: 'Can not edit session type',
    STATIC_ROUTE_USES_TUNNEL_CIDR: 'Static Route uses Tunnel CIDR',
    IP_SEC_SITE_USES_GLOBAL_PSK: 'IP Sec Site uses global Pre Shared Key',
    INVALID_TUNNEL_ADDRESS: 'Invalid Tunnel Address',
    INVALID_TUNNEL_PREFIX: 'Invalid Tunnel Prefix',
    SERVICE_SOURCE_PORT_ALREADY_EXISTS: 'Service Source Port already exists',
    INVALID_PERSISTENCE_MODE_FOR_TCP: 'Invalid Persistence mode for TCP',
    NONE_SAME_PRIVATE_NETWORK: 'None same Private Network',
    CANNOT_ADD_SAME_CLOUD_SERVER: 'Can not add same Cloud Server',
    UNABLE_TO_FIND_POOL: 'Unable to find Pool',
    NETWORK_ADDRESS_CANNOT_BE_SUBNET_OF_IP_SEC_PEER_SUBNET:
      'Network Address can not be subnet of IP Sec peer Subnet',
    IP_SEC_PEER_SUBNET_CANNOT_BE_SUBNET_OF_NETWORK_ADDRESS:
      'IP Sec Peer Subnet can not be Subnet of Network Address',
    INVALID_NEXT_HOP: 'Invalid Next Hop',
    OVERLAP_NEXT_HOP_ADDRESS_WITH_TUNNEL_CIDR:
      'Overlap Next Hop Address with Tunnel CIDR',
    CANNOT_ACCESS_NETWORK: 'Can not access network',
    CANNOT_ADD_ANOTHER_NETWORK_CARD: 'Can not add another Network Card',
    INVALID_NETWORK_TYPE: 'Invalid Network Type',
    NETWORK_ALREADY_IN_USE: 'Network Already in use',
    INVALID_CIDR: 'Invalid CIDR',
    INVALID_NETWORK_CARD: 'Invalid Network Card',
    INVALID_VALUE: 'Invalid Value',
    INVALID_CIDR_FOR_ROUTES: 'Invalid CIDR for routes',
    INVALID_ROUTE: 'Invalid Route',
    CANNOT_DELETE_PUBLIC_CARD: 'Can not delete Public Card',
    CANNOT_DELETE_LAST_PRIVATE_CARD: 'Can not Delete last Private Card',
    CARD_IS_USED_AS_USER_ROUTE: 'Card is used as User Route',
    INVALID_START_IP_ADDRESS: 'Invalid start IP Address',
    POOL_RANGE_OVERPLAPS_EXISTING_POOLS: 'Pool range overlaps existing Pools',
    IP_ADDRESS_CONFLICTS_WITH_THE_NETWORK_CARDS:
      'IP Address conflicts with the Network Cards',
    VPN_PROFILE_USES_THIS_POOL: 'VPN Profile uses this Pool',
    PROFILE_NAME_ALREADY_EXISTS: 'Profile name already exists',
    VPN_USER_USES_THIS_PROFILE: 'A VPN User uses this Profile',
    FAILED_TO_FETCH_DATA: 'Failed to fetch data',
    VCENTER_MISMATCH: 'Vcenter mismatch',
    STORAGE_ACCOUNT_IS_PROTECTED: 'Storage Account is protected',
    VPN_CONCENTRATOR_IS_PROTECTED: 'VPN Concentrator is protected',
    REMOTE_ENDPOINT_CANNOT_BE_ASSIGNED_TO_NETWORK_CARD:
      'Remote Endpoint can not be assigned to Network Card',
    CANNOT_DELETE_LAST_INTERNAL_NETWORK_CARD:
      'Can not delete last internal network card',
    NO_DELETE_UPLINK_NETWORK_CARD: 'No delete uplink network card',
    RESOURCE_NAME_ALREADY_EXISTS: 'Resource name already exists',
    CANNOT_EDIT_RESOURCE_TYPE: 'Can not edit resource type',
    CANNOT_DELETE_RESOURCE: 'Can not delte resource',
    LICENSE_INSTANCE_ALREADY_EXISTS: 'License instance already exists',
    LICENSE_INSTANCE_NOT_ACTIVE: 'License instance not active',
    ADDON_SERVICE_INSTANCE_ALREADY_EXISTS:
      'Addon service instance already exists',
    ADDON_SERVICE_INSTANCE_NOT_ACTIVE: 'Addon service instance not active',

    NOT_FOUND: 'Not found.',
    INVALID_TOKEN: 'Invalid token.',

    SERVER_ERROR: 'Server Error',

    INVALID_ROLE: 'Invalid Role',

    CANNOT_EDIT_USER_STATUS: 'Can not edit user status',
    LEAD_REGISTRATION_VAT_VERIFY_FAILED: 'Lead registration vat verify failed',
    OPERATING_SYSTEM_NOT_AVAILABLE: 'Operationg system not available',
    INVALID_CUSTOMER_ROLE: 'Invalid Customer Role',
    NO_ACCESS_CUSTOMER: 'No access customer',
    INVALID_USER: 'Invalid user',
    NO_DEACTIVATE_SELF: 'Can not deactivate your account',
    NO_EDIT_SELF: 'Can not edit self',
    SYSTEM_ADMIN_ONLY: 'System admin only',
    USER_DEACTIVATED: 'User Deactivated',

    RESOURCE_USAGE_EXCEEDED: 'Resource Limit Exceeded',
    DISK_EXIST_IN_BACKUPS: 'Disk exist in backups',
    LOGIN_BLOCKED_RS: 'Login failed',
    REGISTER_BLOCKED_RS: 'Register failed',
    S_DENIES_ACCESS: 'Access Denied',
    R_NOT_FOUND: 'Path not found',
    MISSING_OR_EXPIRED_TOKEN: 'Missing or expired token',
    UNAUTHORISED_DISK_IMAGE: "You can't access this image",
    UNAUTHORISED_NETWORK: "You can't access this network",
    UNAUTHORISED_CPU_TIER: "You can't access this CPU Tier",
    UNAUTHORISED_LOCATION: "You can't access this Location",
    MISSING_PERMISSION: 'You have no permission for this action'
  }
};
